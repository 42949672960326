import VueI18n from 'vue-i18n'

// User defined lang
import plLocale from './dictionaries/pl'
import enLocale from './dictionaries/en'
import nlLocale from './dictionaries/nl'
import deLocale from './dictionaries/de'
import frLocale from './dictionaries/fr'
import uaLocale from './dictionaries/ua'

export const messages = {
  pl: {
    ...plLocale,
  },
  en: {
    ...enLocale,
  },
  nl: {
    ...nlLocale,
  },
  de: {
    ...deLocale,
  },
  fr: {
    ...frLocale,
  },
  ua: {
    ...uaLocale,
  },
}

export const dateTimeFormats: VueI18n.DateTimeFormats = {
  pl: {
    long: {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      weekday: 'long',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    },
  },
  en: {
    long: {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      weekday: 'long',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    },
  },
  nl: {
    long: {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      weekday: 'long',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    },
  },
  de: {
    long: {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      weekday: 'long',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    },
  },
  fr: {
    long: {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      weekday: 'long',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    },
  },
  ua: {
    long: {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      weekday: 'long',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    },
  },
}
