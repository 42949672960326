import _ from "lodash";

const initState = {
    tableData: [],
    tableFields: []
};

export const state = Object.assign({}, _.cloneDeep(initState))

export const mutations = {
    SET_TABLE_DATA(state: any, payload: any) {
        state.tableData = payload;
    },
    SET_TABLE_FIELDS(state: any, payload: any) {
        state.tableFields = payload;
    }
};

export const actions = {
    setTableData({ commit }: any, payload: any) {
        commit('SET_TABLE_DATA', payload);
    },
    setTableFields({ commit }: any, payload: any) {
        commit('SET_TABLE_FIELDS', payload);
    }
};

export const getters = {
    getTableData (state: any) {
        return state.tableData;
    },
    getTableFields(state: any) {
        return state.tableFields;
    },

    objectView(state: any){
        return state;
    }

};


