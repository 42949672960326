<template>
  <b-button
    v-if="item.type !== 'dropdown'"
    :id="item.id ? item.id : null"
    class="text-nowrap"
    :class="item.class"
    :variant="item.variant"
    :size="item.size"
    :disabled="item.disabled"
    @click="item.click"
  >
    <i v-if="item.icon && (item.presentation === 'titleAndIcon' || item.presentation === 'icon')" :class="item.icon"></i>
    {{ item.presentation === 'titleAndIcon' || item.presentation === 'title' ? item.title : '' }}
    <b-tooltip v-if="item.id && item.tooltip && !item.disabled" :target="item.id" triggers="hover" variant="primary" placement="bottom">
      {{ item.tooltip }}
    </b-tooltip>
  </b-button>
  <b-dropdown
    v-else
    :id="item.id ? item.id : null"
    class="text-nowrap"
    :text="item.presentation === 'titleAndIcon' || item.presentation === 'title' ? item.title : ''"
    :class="item.class"
    :variant="item.variant"
    :size="item.size"
    :disabled="item.disabled"
  >
    <template v-if="item.icon && (item.presentation === 'titleAndIcon' || item.presentation === 'icon')" v-slot:button-content>
      <i :class="item.icon"></i>
      {{ item.presentation === 'titleAndIcon' || item.presentation === 'title' ? item.title : '' }}
    </template>
    <b-dropdown-item-button v-for="(listItem, i) in item.items" :key="i" :disabled="listItem.disabled" @click="listItem.click">
      <template v-if="listItem.separation">
        <hr class="m-0 p-0" />
      </template>
      <template v-else>
        <i v-if="listItem.icon" :class="listItem.icon"></i>
        {{ listItem.presentation === 'titleAndIcon' || listItem.presentation === 'title' ? listItem.title : '' }}
      </template>
    </b-dropdown-item-button>
  </b-dropdown>
</template>

<script>
export default {
  name: 'ToolBarItem',
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style scoped></style>
