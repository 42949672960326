<template>
  <b-modal
    id="user-view-settings"
    v-model="showDlg"
    size="xl"
    :title="$t('components.viewSettings')"
    no-close-on-backdrop
    :cancel-title="$tc('commands.cancel')"
  >
    <div class="row">
      <div class="col">
        <b-tabs v-model="tabIndex" pills vertical card nav-class="v-tabs" class="data-tabs">
          <b-tab :title="$t('common.fields')">
            <div class="row">
              <div class="col">
                <b-button-toolbar>
                  <b-button id="add-fields-btn" variant="outline-primary" size="sm" @click="addFiledsMode = !addFiledsMode">
                    <i v-if="addFiledsMode" class="ri-check-line"></i>
                    <i v-if="!addFiledsMode" class="ri-add-line"></i>
                    {{ addFiledsMode ? $t('commands.ok') : $t('commands.addFields') }}
                  </b-button>
                </b-button-toolbar>
              </div>
            </div>
            <div class="row mt-2">
              <div v-if="addFiledsMode" class="col-6 pr-0">
                <b-card no-body class="p-1">
                  <h5> {{ $t('common.availableFields') }}</h5>
                  <div class="list-group">
                    <ObjectField
                      :fields="this.objectFields"
                      :currField="currObjectField"
                      @active-field="onActiveField"
                      @select-field="onSelectField"
                    ></ObjectField>
                  </div>
                </b-card>
              </div>
              <div class="col-6 pr-0">
                <b-card no-body class="p-1">
                  <h5>{{ $t('common.selectedFields') }}</h5>
                  <span v-if="items.length === 0" class="ml-2"> {{ $t('common.noFieldsAvailable') }}</span>
                  <draggable class="list-group col-md-12" group="columns" v-model="items">
                    <div v-for="(field, idx) in items" :key="idx">
                      <div class="row mb-1 field-item" :class="{ 'item-active': currField === field }" @click="onClickField(field)">
                        <div class="col d-flex">
                          <div class="w-100">
                            <i class="ri-arrow-right-s-line mr-2 float-left"></i>
                            <span>{{ field.label }}</span>
                          </div>
                          <i class="ri-arrow-up-down-line mr-2"></i>
                          <a href="javascript:void(0);" @click="deleteSelectedField(idx)"><i class="ri-close-line text-danger float-right"></i></a>
                        </div>
                      </div>
                    </div>
                  </draggable>
                </b-card>
              </div>
              <div class="col" v-if="currField && !addFiledsMode">
                <b-card>
                  <div class="row">
                    <div class="col">
                      <b-form-group horizontal :label-cols="3" :label="$t('table.visible')" label-for="field-visible">
                        <b-form-checkbox id="field-visible" v-model="currField.visible" switch size="sm" class="mt-1"> </b-form-checkbox>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <b-form-group horizontal :label-cols="3" :label="$t('table.title')" label-for="field-label">
                        <b-input-group>
                          <b-form-input id="field-label" v-model.trim="currField.label" type="text" size="sm" autofocus />
                          <b-input-group-append>
                            <Translation v-model="currField.lang" input="label" />
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <b-form-group horizontal :label-cols="3" :label="$t('common.sortable')" label-for="field-sortable">
                        <b-form-checkbox id="field-sortable" v-model="currField.sortable" switch size="sm" class="mt-1"> </b-form-checkbox>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <b-form-group horizontal :label-cols="3" :content-cols="6" :label="`${$t('common.width')}, %`" label-for="field-width">
                        <b-form-input id="field-width" v-model.number="currField.width" type="number" size="sm" />
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <b-form-group horizontal :label-cols="3" :content-cols="6" :label="$t('common.format')" label-for="field-format">
                        <b-form-input id="field-format" v-model.trim="currField.format" type="text" size="sm" />
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <b-form-group horizontal :label-cols="3" :label="$t('common.styles')" label-for="field-styles">
                        <b-form-input id="field-styles" v-model.trim="currField.styles" type="text" size="sm" />
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <b-form-group horizontal :label-cols="3" :label="$t('common.quickFilter')" label-for="field-quick-filter">
                        <b-form-checkbox
                          id="field-quick-filter"
                          v-model="currField.quickFilter"
                          :disabled="currField.filterDisabled"
                          switch
                          size="sm"
                          class="mt-1"
                          @change="onChangeQuickFilter(currField)"
                        >
                        </b-form-checkbox>
                      </b-form-group>
                    </div>
                  </div>
                </b-card>
              </div>
            </div>
          </b-tab>
          <b-tab :title="$t('common.filters')">
            <div class="row">
              <div class="col">
                <b-button-toolbar>
                  <b-button id="add-fields-btn" variant="outline-primary" size="sm" @click="addFiledsMode = !addFiledsMode">
                    <i v-if="addFiledsMode" class="ri-check-line"></i>
                    <i v-if="!addFiledsMode" class="ri-add-line"></i>
                    {{ addFiledsMode ? $t('commands.ok') : $t('commands.addFilters') }}
                  </b-button>
                </b-button-toolbar>
              </div>
            </div>
            <div class="row mt-2">
              <div v-if="addFiledsMode" class="col-6 pr-0">
                <b-card no-body class="p-1">
                  <h5> {{ $t('common.availableFields') }}</h5>
                  <div class="list-group">
                    <ObjectField
                      :fields="this.objectFields"
                      :currField="currObjectField"
                      @active-field="onActiveField"
                      @select-field="onSelectField"
                    ></ObjectField>
                  </div>
                </b-card>
              </div>
              <div class="col-6 pr-0">
                <b-card no-body class="p-1">
                  <h5>{{ $t('common.selectedFilters') }}</h5>
                  <span v-if="filters.length === 0" class="ml-2">{{ $t('common.noFiltersAvailable') }}</span>
                  <draggable class="list-group col-md-12" group="columns" v-model="filters">
                    <div v-for="(filter, idx) in filters" :key="idx">
                      <div class="row mb-1 field-item" :class="{ 'item-active': currFilter === filter }" @click="onClickFilter(filter)">
                        <div class="col d-flex">
                          <div class="w-100">
                            <i class="ri-arrow-right-s-line mr-2 float-left"></i>
                            <span>{{ filter.label }}</span>
                          </div>
                          <i class="ri-arrow-up-down-line mr-2"></i>
                          <a href="javascript:void(0);" @click="deleteFilter(idx)"><i class="ri-close-line text-danger float-right"></i></a>
                        </div>
                      </div>
                    </div>
                  </draggable>
                </b-card>
              </div>
              <div class="col" v-if="currFilter && !addFiledsMode">
                <b-card>
                  <div class="row">
                    <div class="col">
                      <b-form-group horizontal :label-cols="3" :label="$t('table.use')" label-for="field-use">
                        <b-form-checkbox id="field-use" v-model="currFilter.use" switch size="sm" class="mt-1"> </b-form-checkbox>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <b-form-group horizontal :label-cols="3" :label="$t('table.title')" label-for="item-title">
                        <b-input-group>
                          <b-form-input id="item-title" v-model.trim="currFilter.label" type="text" size="sm" autofocus />
                          <b-input-group-append>
                            <Translation v-model="currFilter.lang" input="label" />
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row" v-if="operators.length > 0">
                    <div class="col">
                      <b-form-group horizontal :label-cols="3" :label="$t('table.operator')">
                        <b-form-select v-model="currFilter.operator" :options="operators" size="sm" @change="onChangeOperator"></b-form-select>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row" v-if="currFilter.operator !== 'filled' && currFilter.operator !== 'notFilled'">
                    <div class="col">
                      <b-form-group horizontal :label-cols="3" :label="$t('table.value')">
                        <div v-if="currFilter.fieldType === 'object'">
                          <f-select v-model="currFilter.value" select-btn :value-type="currFilter.ref" :multiple="multiple"> </f-select>
                        </div>
                        <div v-else-if="currFilter.fieldType === 'enum'">
                          <b-form-select v-model="currFilter.value" :options="enumOptions" :multiple="multiple" size="sm"> </b-form-select>
                        </div>
                        <div v-else-if="currFilter.fieldType === 'boolean'">
                          <b-form-select v-model="currFilter.value" :options="booleanOptions" size="sm"> </b-form-select>
                        </div>
                        <div v-else>
                          <b-form-input v-model="currFilter.value" :type="currFilter.fieldType" size="sm" />
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                </b-card>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button-toolbar class="float-right">
          <b-button variant="outline-secondary" size="sm" class="mr-1" @click="onSetDefault">
            {{ $t('commands.setDefault') }}
          </b-button>
          <b-button variant="outline-danger" size="sm" class="mr-1" @click="handleCancel">
            {{ $t('commands.cancel') }}
          </b-button>
          <b-button variant="primary" size="sm" @click="handleOk">
            {{ $t('commands.ok') }}
          </b-button>
        </b-button-toolbar>
      </div>
    </template>
  </b-modal>
</template>

<script>
import _ from 'lodash'
import draggable from 'vuedraggable'
import { uuid } from 'vue-uuid'
import UserViewSetting from '@/dto/UserViewSetting.json'
import { mapGetters } from 'vuex'
import { defineFieldType, defineDataType } from '@/utils/app-service'
import Translation from '@/components/common/translation.vue'
import ObjectField from './object-field.vue'
import Languages from '@/dto/Languages.json'
import { getLocaleInstance } from '@/lang/local'

export default {
  name: 'UserViewSettings',

  components: {
    draggable,
    Translation,
    ObjectField,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    viewSettings: {
      type: Object,
      required: true,
      default: null,
    },
    userViewSettings: {
      type: Object,
      default: null,
    },
  },

  watch: {
    tabIndex() {
      this.initCurrentItem()
    },
  },

  data() {
    return {
      tabIndex: 0,
      items: [],
      filters: [],
      objectFields: [],
      currField: null,
      currFilter: null,
      currObjectField: null,
      booleanOptions: [
        { value: true, text: this.$tc('common.yes') },
        { value: false, text: this.$tc('common.no') },
      ],
      enumOptions: [],
      operators: [],
      multiple: false,
      definedOperators: {
        object: ['eq', 'ne', 'inList', 'notInList', 'filled', 'notFilled'],
        enum: ['eq', 'ne', 'inList', 'notInList', 'filled', 'notFilled'],
        text: ['eq', 'ne', 'iLike', 'notILike'],
        number: ['eq', 'ne', 'lte', 'lt', 'gt', 'gte'],
        date: ['eq', 'ne', 'lte', 'lt', 'gt', 'gte'],
      },
      addFiledsMode: false,
    }
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),

    showDlg: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },

  mounted() {
    this.initObjectMeta()
    this.initSettings()
    this.initCurrentItem()
  },

  methods: {
    async initObjectMeta() {
      this.objectFields = []

      if (this.viewSettings.appObject) {
        await this.$store.dispatch('appObjects/findByPk', { noCommit: true, params: { id: this.viewSettings.appObject.id } }).then(async (response) => {
          if (response.data) {
            this.fillObjectFields(1, this.objectFields, response.data.model)
          }
        })

        this.onChangeFieldsTab()
      }
    },

    async fillObjectFields(lvl, fields, model, parent = null) {
      await this.$store.dispatch('app/getObjectMeta', { params: { objectType: model } }).then((response) => {
        if (response.data) {
          const objectMeta = response.data

          for (const objectField of objectMeta) {
            let currLvl = lvl

            if (objectField.type !== 'VIRTUAL') {
              const label = this.$t(`table.${objectField.name}`)

              const fieldPath = objectField.fieldPath ? objectField.fieldPath : objectField.name
              const fieldLabel = label.includes('table.') ? objectField.name : label

              let fieldType
              if (fieldPath !== objectField.name && objectField.ref && objectField.model) {
                fieldType = 'object'
              } else {
                fieldType = defineFieldType(objectField.type)
              }

              const field = {
                name: objectField.name,
                path: fieldPath,
                label: fieldLabel,
                fullName: parent ? `${parent.fullName}.${objectField.name}` : objectField.name,
                fullPath: parent ? `${parent.fullName}.${fieldPath}` : fieldPath,
                fullLabel: parent ? `${parent.fullLabel} [${fieldLabel}]` : fieldLabel,
                fieldType,
                dataType: defineDataType(objectField.type),
                ref: objectField.ref,
                sortBy: objectField.sortBy,
                model: objectField.model,
                childs: [],
              }

              if (fieldType === 'object' && objectField.name !== 'id' && lvl < 4) {
                this.fillObjectFields(++currLvl, field.childs, objectField.model, field)
              }

              fields.push(field)
            }
          }
        }
      })
    },

    onActiveField(field) {
      this.currObjectField = field
    },

    onChangeFieldsTab() {
      this.currObjectField = null
      if (this.objectFields.length > 0) {
        this.currObjectField = this.objectFields[0]
      }
    },

    onSelectField(field) {
      if (this.tabIndex === 0) {
        const existedField = this.items.find((el) => {
          return el.fieldPath === field.fullPath
        })

        if (!existedField) {
          this.items.push({
            visible: true,
            name: field.fullName,
            fieldPath: field.fullPath,
            label: field.fullLabel,
            sortable: false,
            sortDisabled: false,
            quickFilter: false,
            filterDisabled: field.name === 'id',
            fieldType: field.fieldType,
            dataType: field.dataType,
            ref: field.ref,
            sortBy: field.sortBy,
            width: null,
            format: '',
            styles: null,
            lang: this.getLang(field.name),
          })

          this.currField = null
          if (this.items.length > 0) {
            this.currField = this.items[0]
          }
        }
      } else {
        const existedFilter = this.filters.find((el) => {
          return el.fieldPath === field.fullPath
        })

        if (!existedFilter) {
          this.filters.push({
            use: true,
            name: field.fullName,
            fieldPath: field.fullPath,
            label: field.fullLabel,
            fieldType: field.fieldType,
            dataType: field.dataType,
            ref: field.ref,
            format: '',
            operator: null,
            value: null,
            hidden: false,
            lang: this.getLang(field.name),
          })

          this.currFilter = null
          if (this.filters.length > 0) {
            this.onClickFilter(this.filters[0])
          }
        }
      }
    },

    deleteSelectedField(idx) {
      this.items.splice(idx, 1)
      this.currField = null

      if (this.items.length > 0) {
        this.currField = this.items[0]
      }
    },

    deleteFilter(idx) {
      this.filters.splice(idx, 1)

      this.currFilter = null
      if (this.filters.length > 0) {
        this.currFilter = this.filters[0]
      }
    },

    async handleOk() {
      if (!this.userViewSettings) {
        const newSettings = _.cloneDeep(UserViewSetting)
        newSettings.id = uuid.v4()
        newSettings.name = this.viewSettings.name
        newSettings.title = this.viewSettings.title
        newSettings.lang = this.viewSettings.lang
        newSettings.viewType = this.viewSettings.viewType
        newSettings.viewId = this.viewSettings.id
        newSettings.items = this.items
        newSettings.filters = this.filters
        newSettings.userId = this.currentUser.id

        await this.$store.dispatch('userViewSettings/create', newSettings)
      } else {
        const settings = _.cloneDeep(this.userViewSettings)
        settings.items = this.items
        settings.filters = this.filters

        await this.$store.dispatch('userViewSettings/update', settings)
      }

      this.$emit('update-settings')
      this.showDlg = false
    },

    handleCancel() {
      this.showDlg = false
    },

    async onSetDefault() {
      if (this.userViewSettings) {
        await this.$store.dispatch('userViewSettings/delete', { id: this.userViewSettings.id })
      }

      this.$emit('update-settings')
      this.showDlg = false
    },

    async initSettings() {
      if (!this.viewSettings) {
        this.showDlg = false
        return
      }

      if (this.userViewSettings) {
        this.items = _.cloneDeep(this.userViewSettings.items)
        this.filters = _.cloneDeep(this.userViewSettings.filters)
      } else {
        this.items = _.cloneDeep(this.getFildItems(this.viewSettings.items))
        this.filters = _.cloneDeep(this.viewSettings.filters)
      }
    },

    getFildItems(items) {
      const listItem = items.find((el) => el.name === '_list')

      if (!listItem) {
        for (const item of items) {
          if (item.items) {
            const listItems = this.getFildItems(item.items)

            if (listItem) {
              return listItems
            }
          }
        }
      } else {
        return listItem.items
      }
    },

    initCurrentItem() {
      this.addFiledsMode = false

      if (this.tabIndex === 0 && this.items.length > 0) {
        this.currField = this.items[0]
      }

      if (this.tabIndex === 1 && this.filters.length > 0) {
        this.onClickFilter(this.filters[0])
      }
    },

    onClickField(field) {
      this.currField = field
    },

    onClickFilter(filter) {
      this.currFilter = filter
      this.multiple = this.currFilter.operator === 'inList' || this.currFilter.operator === 'notInList'

      let operators = []

      if (this.currFilter.fieldType !== 'boolean') {
        operators = this.definedOperators[this.currFilter.fieldType]

        this.operators = operators.map((el) => {
          return { value: el, text: this.$tc(`operators['${el}']`) }
        })
      }

      if (this.currFilter.dataType === 'enum') {
        this.enumOptions = this.$enums(this.currFilter.ref)
      }
    },

    getLang(name) {
      const lang = { label: {} }

      for (const language of Languages) {
        const locale = language.code
        const i18nLocal = getLocaleInstance(locale)
        const translate = i18nLocal.tc(`table.${name}`)
        if (!translate.includes('table.')) {
          lang.label[locale] = translate
        }
      }

      return lang
    },

    onChangeOperator() {
      this.currFilter.value = null

      if (this.currFilter.operator === 'inList' || this.currFilter.operator === 'notInList') {
        this.currFilter.value = []
        this.multiple = true
      } else {
        this.multiple = false
      }
    },

    onChangeQuickFilter(field) {
      const filterIndex = this.filters.findIndex((el) => {
        return el.name === field.name
      })

      if (field.quickFilter === true && filterIndex === -1) {
        this.filters.push({
          use: true,
          name: field.name,
          fieldPath: field.fieldPath,
          label: field.label,
          fieldType: field.fieldType,
          dataType: field.dataType,
          ref: field.ref,
          format: field.format,
          operator: null,
          value: null,
          hidden: false,
          lang: field.lang,
        })
      } else if (field.quickFilter !== true && filterIndex > -1) {
        this.filters.splice(filterIndex, 1)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.list-group {
  max-height: 50vh;
  overflow-y: auto;
  padding-left: 0.85rem;
  padding-right: 0.85rem;
}

.field-item {
  padding: 0.35rem;
  border: solid #adb5bd 1px;
  background-color: #fefefe;
  border-radius: 0.25rem;
  font-size: 16px;
  cursor: pointer;
}
.item-active {
  background-color: #dee2e6;
}
</style>
