import moment from 'moment'
import { uuid } from 'vue-uuid'
import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import Vue from 'vue'
import { BImg } from 'bootstrap-vue'
Vue.component('b-img', BImg)

export const newUuid = () => {
  return uuid.v4()
}

export const catalogPresentation = (objCatalog) => {
  if (objCatalog) {
    return objCatalog.name
  }
}

export const documentPresentation = (objDocument, strAttr = '') => {
  if (objDocument) {
    if (strAttr === '') {
      return 'Nr ' + objDocument.numberStr + ' od ' + moment(objDocument.date).format('DD.MM.YYYY')
    } else {
      const objDocumentAttr = objDocument[strAttr]
      if (objDocumentAttr) {
        return 'Nr ' + objDocumentAttr.number + ' od ' + moment(objDocumentAttr.date).format('DD.MM.YYYY')
      }
    }
  }
}

export const documentsAttrByName = (doc, attrName) => {
  if (doc) {
    const attr = doc[attrName]
    if (Array.isArray(attr)) {
      return attr[0]
    } else {
      return attr
    }
  }
}

export const dateTimePresentation = (valueDateTime) => {
  if (valueDateTime) {
    return moment(valueDateTime).format('DD.MM.YYYY HH:mm:ss')
  } else {
    return ''
  }
}

export const dateTimeShortPresentation = (valueDateTime) => {
  if (valueDateTime) {
    return moment(valueDateTime).format('DD.MM.YYYY HH:mm')
  } else {
    return ''
  }
}

export const weightPresentation = (value) => {
  if (value) {
    return Number(value).toFixed(2) + ' T'
  } else {
    return '0.00 T'
  }
}

export const showMessageBox = (view, textMessage, fontSize = 22, isRedText = false, windowSize = '', showSeconds = 10 * 1000) => {
  let bodyTextColor = 'text-black'
  let srcIcon = '../../../img/info.png'
  if (isRedText) {
    bodyTextColor = 'text-danger'
    srcIcon = 'img/delete.png'
  }
  let result = ''

  const h = view.$createElement
  const messageVNode = h('div', { class: ['foobar'] }, [
    h('b-row', [
      h('div', { class: ['col-md-3'] }, [h('img', { attrs: { src: srcIcon, fluid: true, thumbnail: true, center: true } })]),
      h('div', { class: ['col-md-9'] }, [h('p', { class: [bodyTextColor + ' font-' + fontSize + ' font-weight-bold'] }, [textMessage])]),
    ]),
  ])

  //

  // view.$bvModal
  //   .msgBoxOk(textMessage, {
  view.$bvModal
    .msgBoxOk([messageVNode], {
      size: windowSize,
      buttonSize: 'lg',
      okTitle: 'OK - (5)',
      title: 'UWAGA.',
      centered: true,
      bodyClass: bodyTextColor + ' font-' + fontSize + ' font-weight-bold',
      footerClass: 'msg-footer',
      id: 'info-modal-common',
    })
    .then((value) => {
      result = value
    })
    .catch((err) => {
      console.error(err)
    })

  setTimeout(() => {
    view.$bvModal.hide('info-modal-common')
  }, showSeconds)

  return result
}

export const showQuestion = (view, textMessage, questionActionOk, questionActionCancel = undefined, objParameters = undefined, windowSize = '') => {
  let result = ''
  view.$bvModal
    .msgBoxConfirm(textMessage, {
      size: windowSize,
      centered: true,
      bodyClass: 'text-black font-28 font-weight-bold',
      okTitle: 'TAK - (5)',
      cancelTitle: 'NIE - (2)',
    })
    .then((value) => {
      result = value
      if (result) {
        questionActionOk(objParameters)
      } else {
        if (questionActionCancel !== undefined) {
          questionActionCancel(objParameters)
        }
      }
    })
    .catch((err) => {
      console.error(err)
    })
  return result
}

export const fillObject = (objReceiver, objSource, includeArray = [], excludeArray = []) => {
  for (const propName in objSource) {
    let isInclude = true
    if (includeArray.length > 0) {
      const findValue = includeArray.find((e) => e === propName)
      if (!findValue) {
        isInclude = false
      }
    }
    let isExclude = false
    if (excludeArray.length > 0) {
      const findValue = excludeArray.find((e) => e === propName)
      if (findValue) {
        isExclude = true
      }
    }
    if (isInclude === false || isExclude === true) {
      continue
    }

    if (Object.prototype.hasOwnProperty.call(objReceiver, propName)) {
      objReceiver[propName] = objSource[propName]
    }
  }
}

export const rgbToHex = (r, g, b) => {
  var rgb = (r << 16) | (g << 8) | b

  return '#' + rgb.toString(16).padStart(6, 0)
}

export const hex2rgb = (hex) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  return { r, g, b }
}

export const bytesArray2BigInt64 = (inputArray) => {
  let intValue = 0
  const buff = Buffer.from(inputArray)
  try {
    intValue = buff.readBigUInt64BE(0)
  } catch (err) {
    intValue = 0
    console.error(err)
  }
  return intValue
}

export const pageSizeChange = (size) => {
  const patternA5 = /^[Aa]5$/
  const patternA4 = /^[Aa]4$/

  if (patternA5.test(size)) {
    return { height: 148, width: 90 }
  }
  if (patternA4.test(size)) {
    return 'A4'
  }

  return { height: 148, width: 90 }
}

export const createOutgoingSms = (view, messageText, phoneNumber, disposition) => {
  // view.$store
  // .dispatch('products/findByPk', { noCommit: true, params: { id: docDisposition.productId } })
  // .then((response) => {
  //   if (response.status === 200) {
  //     const product = response.data
  //     const notUsedProductCondition = product.notUsedProductCondition
  //     if (notUsedProductCondition === true) {
  //       docDisposition.productCondition = 'TOWAR WOLNY OD SALMONELI'
  //     }
  //   }
  // })
  // .catch((error) => {
  //   console.error(error)
  // })
}

export const sendExternalUserWeight = async (objectItem) => {
  try {
    const url = 'https://skup-api-eu.azurewebsites.net/api/MeasuredWeight' // Замініть на реальну адресу
    const sendRes = await axios.post(url, objectItem, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Basic aGVzaW50ZWdyYXRpb246SXhLbFo4cWlYeHdoMUI0Y3k4T1U=',
      },
    })
  } catch (e) {
    console.log(e)
  }
}

export const rawQuery = async (payload) => {
  const gueryData = cloneDeep(payload)
  gueryData.sid = 'b0c95264-07b2-4e72-b705-fee2102505d6' // theare was genarate sid

  return axios
    .post(`app/raw-query`, gueryData)
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

export const getNameMainCompany = async (view) => {
  try {
    const mainCompany = await view.$store.dispatch('appSettingItems/findAll', {
      noCommit: true,
      params: {
        filter: {
          predefinedName: 'mainCompany',
        },
      },
    })
    if (mainCompany.status == 200 && mainCompany.data.length) {
      const idCompany = await view.$store.dispatch('appSettings/findAll', {
        params: {
          filter: {
            appSettingItemId: mainCompany.data[0].id,
          },
        },
      })

      if (idCompany.status == 200 && idCompany.data.length) {
        const customers = await view.$store.dispatch('firms/findAll', {
          noCommit: true,
          params: {
            filter: {
              id: idCompany.data[0].valueString,
            },
          },
        })
        if (customers.status == 200 && customers.data.length) {
          return {
            shortNameMainCompany: customers.data[0].name,
            longNameMainCompany: customers.data[0].name,
            addressStreet: customers.data[0].address ? customers.data[0].address.split(',')[0] : '',
            addressNumber: customers.data[0].address ? customers.data[0].address.split(',')[1] : '',
          }
        }
      }
    }
    return {
      shortNameMainCompany: '',
      longNameMainCompany: '',
      addressStreet: '',
      addressNumber: '',
    }
  } catch (e) {
    console.log(e)
    return {
      shortNameMainCompany: '',
      longNameMainCompany: '',
    }
  }
}

export const validatePassword = async (payload) => {
  try {
    const getOption = {
      method: 'post',
      url: `/users/validate_password`,
      headers: { 'Content-Type': 'application/json' },
      data: {
        login: payload.login,
        password: payload.password,
      },
    }
    const res = await axios(getOption)
    if (res.status == 200 && res.data.valid) {
      return true
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}
