import _ from 'lodash'
import { ActionContext } from 'vuex'
import { IState } from '@/store/service/state'
import { commonState } from '../service/state'
import { commonMutations } from '../service/mutations'
import { commonGetters } from '../service/getters'
import { findAll, findByPk, createItem, updateItem, deleteItem, addNewItem, changeDeletionMark, resetState } from '../service/actions'
import { uuid } from 'vue-uuid'

import BasicObject from '@/dto/ViewSettings.json'
import Vue from 'vue'
const apiUrl = 'view_settings'

export const state = Object.assign({}, _.cloneDeep(commonState))

export const mutations = {
  ...commonMutations,

  addObjectItem(state: IState, payload: any) {
    payload.items.splice(payload.index, 0, payload.item)
  },

  moveObjectItem(state: IState, payload: any) {
    if (payload.toIndex < 0 || payload.toIndex > payload.items.length - 1) {
      return
    }

    const itemFrom = payload.items.splice(payload.fromIndex, 1)
    payload.items.splice(payload.toIndex, 0, itemFrom[0])
  },

  deleteObjectItem(state: IState, payload: any) {
    payload.items.splice(payload.index, 1)
  },

  setObjectItem(state: IState, payload: any) {
    Vue.set(payload.items, payload.index, payload.item)
  },
}

export const actions = {
  async findAll(context: ActionContext<IState, any>, payload: any) {
    return findAll(context, apiUrl, payload)
  },

  async findByPk(context: ActionContext<IState, any>, payload: any) {
    return findByPk(context, apiUrl, payload, beforeOpenItem)
  },

  async create(context: ActionContext<IState, any>, payload: any) {
    return createItem(context, apiUrl, payload)
  },

  async update(context: ActionContext<IState, any>, payload: any) {
    return updateItem(context, apiUrl, payload)
  },

  async delete(context: ActionContext<IState, any>, payload: any) {
    return deleteItem(context, apiUrl, payload)
  },

  async changeDeletionMark(context: ActionContext<IState, any>, payload: any) {
    return changeDeletionMark(context, apiUrl, payload)
  },

  async addNew(context: ActionContext<IState, any>, payload: any) {
    return addNewItem(context, { basicObject: BasicObject }, payload, beforeAddNewItem)
  },

  resetState(context: ActionContext<IState, any>) {
    resetState(context)
  },
}

export const getters = { ...commonGetters }

function beforeOpenItem(viewData: any) {
  viewData.expandedItems = []
}

async function beforeAddNewItem(viewData: any, context: any) {
  if (viewData.object.appObject) {
    context
      .dispatch('findAll', {
        params: {
          filter: {
            appObjectId: viewData.object.appObject.id,
            viewType: viewData.object.viewType,
          },
        },
      })
      .then((response: any) => {
        if (!response.data.length) {
          viewData.object.isDefault = true
        }
      })
      .catch((error: any) => {
        console.error(error)
      })
  }

  if (viewData.object?.viewType === 'list' || viewData.object?.viewType === 'select') {
    viewData.object.items = [
      {
        id: uuid.v4(),
        name: '_list',
        label: 'Lista',
        type: 'viewList',
        icon: 'ri-file-list-line',
        hasLabel: false,
        visible: true,
        newRow: true,
        fieldCols: 12,
        stales: null,
        classes: null,
        lang: {
          label: {
            pl: 'Lista',
            nl: 'List',
            en: 'List',
            de: 'List',
            fr: 'List',
          },
        },
        items: [],
      },
    ]
  }

  viewData.expandedItems = []
}
