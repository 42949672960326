<template>
  <b-modal
    id="object-advanced-filter"
    v-model="showDlg"
    size="lg"
    :title="$t('components.advancedSearch')"
    no-close-on-backdrop
    :cancel-title="$tc('commands.cancel')"
    @ok="handleOk"
    @hide="handleHide"
  >
    <div class="row">
      <div class="col">
        <b-form-group horizontal :label-cols="3" :label="$t('table.searchField')">
          <b-form-select
            v-model="filter.fieldName"
            :options="objectFields"
            value-field="name"
            size="sm"
            @change="onChangeSearchField"
            :state="filter.fieldName !== null"
          ></b-form-select>
        </b-form-group>
      </div>
    </div>
    <div class="row" v-if="operators.length > 0">
      <div class="col">
        <b-form-group horizontal :label-cols="3" :label="$t('table.operator')">
          <b-form-select v-model="filter.operator" :options="operators" size="sm" @change="onChangeOperator"></b-form-select>
        </b-form-group>
      </div>
    </div>
    <div class="row" v-if="filter.operator !== 'filled' && filter.operator !== 'notFilled'">
      <div class="col">
        <b-form-group horizontal :label-cols="3" :label="$t('table.value')">
          <div v-if="filter.fieldType === 'object'">
            <f-select v-model="filter.value" select-btn :multiple="multiple" :value-type="filter.ref"> </f-select>
          </div>
          <div v-else-if="filter.fieldType === 'enum'">
            <b-form-select v-model="filter.value" :options="enumOptions" :multiple="multiple" size="sm"> </b-form-select>
          </div>
          <div v-else-if="filter.fieldType === 'boolean'">
            <b-form-select v-model="filter.value" :options="booleanOptions" size="sm"> </b-form-select>
          </div>
          <div v-else>
            <b-form-input v-model="filter.value" :type="filter.fieldType" size="sm" />
          </div>
        </b-form-group>
      </div>
    </div>
    <div class="row" v-if="filter.fieldType === 'text' && (filter.operator === 'iLike' || filter.operator === 'notILike')">
      <div class="col">
        <b-form-group horizontal :label-cols="3" :label="$t('common.textPosition')">
          <b-form-radio-group id="text-position" v-model="filter.position" :options="textPositions" name="text-position" class="mt-1"></b-form-radio-group>
        </b-form-group>
      </div>
    </div>
  </b-modal>
</template>

<script>
import _ from 'lodash'
import { defineFieldType, defineDataType } from '@/utils/app-service'

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    model: {
      type: String,
      required: true,
    },
    editFilter: {
      type: Object,
      default: null,
    },
    namespace: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      objectMeta: [],
      filter: {
        fieldName: null,
        fieldPath: null,
        fieldType: null,
        dataType: null,
        ref: null,
        operator: null,
        value: null,
        presentation: '',
        position: 'begin',
      },
      textPositions: ['begin', 'end', 'part'].map((el) => {
        return { value: el, text: this.$tc(`enums.TEXT_POSITION['${el}']`) }
      }),
      objectFields: [],
      enumOptions: [],
      operators: [],
      booleanOptions: [
        { value: true, text: this.$tc('common.yes') },
        { value: false, text: this.$tc('common.no') },
      ],
      definedOperators: {
        object: ['eq', 'ne', 'inList', 'notInList', 'filled', 'notFilled'],
        enum: ['eq', 'ne', 'inList', 'notInList', 'filled', 'notFilled'],
        text: ['eq', 'ne', 'iLike', 'notILike'],
        number: ['eq', 'ne', 'lte', 'lt', 'gt', 'gte'],
        date: ['eq', 'ne', 'lte', 'lt', 'gt', 'gte'],
      },
      multiple: false,
      editMode: false,
    }
  },

  computed: {
    showDlg: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },

  async mounted() {
    await this.initObjectMeta()

    if (this.editFilter) {
      this.editMode = true
      this.filter = _.cloneDeep(this.editFilter)

      this.updateOperators()
      this.multiple = this.filter.operator === 'inList' || this.filter.operator === 'notInList'

      if (this.filter.dataType === 'enum') {
        const fieldData = this.objectMeta.find((el) => {
          return el.name === this.filter.fieldName
        })

        if (fieldData) {
          this.enumOptions = fieldData.values.map((el) => {
            return { value: el, text: fieldData.ref ? this.$enums(fieldData.ref, el) : el }
          })
        }
      }
    }
  },

  methods: {
    async initObjectMeta() {
      if (this.model) {
        await this.$store
          .dispatch('app/getObjectMeta', {
            params: { objectType: this.model, tableName: this.namespace },
          })
          .then((response) => {
            this.objectMeta = response.data

            for (const objField of this.objectMeta) {
              if (objField.name === 'id' || objField.name === 'createdAt' || objField.name === 'updatedAt' || objField.type === 'VIRTUAL') {
                continue
              }

              const text = this.$t(`table.${objField.name}`)

              this.objectFields.push({ ...objField, text: text.includes('table.') ? objField.name : text})
            }
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },

    updateOperators() {
      let operators = []

      if (this.filter.fieldType !== 'boolean') {
        operators = this.definedOperators[this.filter.fieldType]
      }

      this.operators = operators.map((el) => {
        return { value: el, text: this.$tc(`operators['${el}']`) }
      })
    },

    onChangeSearchField() {
      const fieldData = this.objectMeta.find((el) => {
        return el.name === this.filter.fieldName
      })

      this.operators = []
      this.filter.fieldPath = null
      this.filter.fieldType = null
      this.filter.dataType = null
      this.filter.ref = null
      this.filter.operator = null
      this.filter.value = null

      if (fieldData) {
        this.filter.fieldPath = fieldData.fieldPath ? fieldData.fieldPath : this.filter.fieldName

        if (fieldData.ref) {
          this.filter.ref = fieldData.ref
        }

        if (this.filter.fieldPath !== this.filter.fieldName && this.filter.ref) {
          this.filter.fieldType = 'object'
        } else {
          this.filter.fieldType = defineFieldType(fieldData.type)
        }

        this.filter.dataType = defineDataType(fieldData.type)

        this.updateOperators()

        if (this.filter.dataType === 'enum') {
          this.enumOptions = fieldData.values.map((el) => {
            return { value: el, text: fieldData.ref ? this.$tc(`enums.${fieldData.ref}.${el}`) : el }
          })
        }
      } else {
        this.filter.fieldName = null
      }
    },

    onChangeOperator() {
      if (this.filter.operator === 'inList' || this.filter.operator === 'notInList') {
        this.filter.value = []
        this.multiple = true
      } else {
        this.filter.value = null
        this.multiple = false
      }
    },

    handleOk() {
      this.updatePresentation()

      if (this.editMode === true) {
        this.$emit('edit-advanced-filter', this.filter)
      } else {
        this.$emit('add-advanced-filter', this.filter)
      }
    },

    handleHide() {
      this.$emit('hide-advanced-filter')
    },

    updatePresentation() {
      const fieldName = this.$t(`table.${this.filter.fieldName}`)
      const operator = this.filter.fieldType === 'boolean' ? this.$tc(`operators.eq`) : this.$tc(`operators['${this.filter.operator}']`)

      if (this.filter.operator === 'notFilled' || this.filter.operator === 'filled') {
        this.filter.presentation = `${fieldName} ${operator}`
      } else {
        let fieldValue = this.filter.value

        if (this.filter.fieldType === 'boolean') {
          fieldValue = this.filter.value === true ? this.$tc('common.yes') : this.$tc('common.no')
        } else if (this.filter.fieldType === 'enum') {
          if (this.filter.operator === 'inList' || this.filter.operator === 'notInList') {
            fieldValue = this.filter.value
              .map((el) => {
                return this.filter.ref ? this.$tc(`enums.${this.filter.ref}.${el}`) : el
              })
              .join(', ')
          } else {
            fieldValue = this.filter.ref ? this.$tc(`enums.${this.filter.ref}.${fieldValue}`) : fieldValue
          }
        } else if (this.filter.fieldType === 'object') {
          fieldValue = fieldValue.presentation ? fieldValue.presentation : fieldValue.name
        }

        this.filter.presentation = `${fieldName} ${operator} "${fieldValue}"`
      }
    },
  },
}
</script>

<style>
</style>