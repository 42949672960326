export interface IListView {
  list: Array<any>
  folders?: {
    list: Array<any>
    expanded: Array<any>
  }
  fields: Array<any>
  total: number
  page: number
  limit: number
  filters: object
  viewId: string | null
  views: Array<IListView>
  attrs: any
  settings: any
  sort: { sortBy: string | null; sortDesc: boolean }
}

export interface IObjectView {
  viewId: string | null
  object: any
  attrs: any
  settings: any
  views: Array<IListView>
  modified: boolean
}

export interface IState {
  listView: IListView
  objectViews: Array<IObjectView>
}

export const commonState: IState = {
  listView: {
    list: [],
    folders: {
      list: [],
      expanded: [],
    },
    fields: [],
    total: 0,
    page: 1,
    limit: 20,
    filters: {
      filter: {},
      searchStr: '',
      advanced: [],
      quick: [],
    },
    sort: { sortBy: null, sortDesc: false },
    viewId: null,
    views: [],
    attrs: {},
    settings: {
      title: '',
      viewSettings: null,
      userViewSettings: null,
      objectData: {},
      selectedRows: null,
      selectedFolder: null,
    },
  },
  objectViews: [],
}
