import { ActionContext } from 'vuex';
import moment from "moment/moment";

// Типи для state
interface IState {
    deliveryNotes: IDeliveryNote[];
    dispositions: IDisposition[];
    wmsTransactions: IWmsTransactions[];
    wmsTransactionsWithFilter: IWmsTransactionsFilter[]
    period: Date; // Додаємо period
}

interface IDeliveryNote {
    id: number;
    name: string;
}

interface IWmsTransactions {
    id: number;
    name: string;
}

interface IWmsTransactionsFilter    {
    quantity: string
    recordType: string
    recorderType: string
    schemeOfCargoId: string
    schemeOfCargoName: string
    typeOfDocument: string
    customerName: string
    customerId: string
}

interface IDisposition {
    id: number;
    description: string;
}

// Початковий стан
export const state = (): IState => ({
    deliveryNotes: [],
    dispositions: [],
    wmsTransactions: [],
    wmsTransactionsWithFilter: [],
    period: moment().toDate()
});

// Мутації для оновлення, додавання та видалення даних
export const mutations = {
    SET_DELIVERY_NOTES(state: IState, deliveryNotes: IDeliveryNote[]) {
        state.deliveryNotes = deliveryNotes;
    },
    SET_DISPOSITIONS(state: IState, dispositions: IDisposition[]) {
        state.dispositions = dispositions;
    },
    SET_WMS_TRANSACTIONS(state: IState, wmsTransactions: IWmsTransactions[]) {
        state.wmsTransactions = wmsTransactions;
    },
    SET_WMS_TRANSACTIONS_FILTER(state: IState, wmsTransactionsWithFilter: IWmsTransactionsFilter[]) {
        state.wmsTransactionsWithFilter = wmsTransactionsWithFilter;
    },
    SET_PERIOD(state: IState, period: Date) {
        state.period = period; // Розкоментовано і оновлено
    },
};

// Дії для виклику мутацій
export const actions = {
    setDeliveryNotes(context: ActionContext<IState, any>, deliveryNotes: IDeliveryNote[]) {
        context.commit('SET_DELIVERY_NOTES', deliveryNotes);
    },
    setDispositions(context: ActionContext<IState, any>, dispositions: IDisposition[]) {
        context.commit('SET_DISPOSITIONS', dispositions);
    },
    setWmsTransactions(context: ActionContext<IState, any>, wmsTransactions: IWmsTransactions[]) {
        context.commit('SET_WMS_TRANSACTIONS', wmsTransactions);
    },
    setWmsTransactionsFilter(context: ActionContext<IState, any>, wmsTransactionsWithFilter: IWmsTransactionsFilter[]) {
        context.commit('SET_WMS_TRANSACTIONS_FILTER', wmsTransactionsWithFilter);
    },
    setPeriod(context: ActionContext<IState, any>, period: Date) {
        context.commit('SET_PERIOD', period); // Розкоментовано і оновлено
    },
};

// Геттер для отримання даних з state
export const getters = {
    currentView(state: IState): {
        period: Date;
        wmsTransactionsWithFilter: IWmsTransactionsFilter[];
        deliveryNotes: IDeliveryNote[];
        dispositions: IDisposition[];
        wmsTransactions: IWmsTransactions[]
    } {
        return {
            deliveryNotes: state.deliveryNotes,
            dispositions: state.dispositions,
            wmsTransactions: state.wmsTransactions,
            wmsTransactionsWithFilter: state.wmsTransactionsWithFilter,
            period: state.period, // Додаємо period
        };
    },
    getDeliveryNotes(state: IState): IDeliveryNote[] {
        return state.deliveryNotes;
    },
    getDispositions(state: IState): IDisposition[] {
        return state.dispositions;
    }
};
