<template>
  <div ref="buttonList" class="mb-2 d-flex flex-row align-items-center justify-content-between" :class="containerClass" v-resize:resize="checkSize">
    <b-btn-toolbar key-nav>
      <b-button-group class="tool-bar-container">
        <toolbar-item v-for="(item, i) in shownButtons" :key="i" :item="item" ref="button" />
      </b-button-group>
    </b-btn-toolbar>
    <b-dropdown v-if="hiddenButtons.length > 0" right size="sm" variant="link" class="float-right" toggle-class="t-menu-btn" no-caret>
      <template v-slot:button-content>
        <i class="ri-menu-line icon-grey t-menu-icon"></i>
      </template>
      <context-item v-for="(item, i) in hiddenButtons" :key="i" :item="item" />
    </b-dropdown>
  </div>
</template>

<script>
import ToolbarItem from './item.vue'
import ContextItem from './context-item.vue'

export default {
  name: 'CommonToolbar',

  components: { ToolbarItem, ContextItem },

  props: {
    items: {
      type: Array,
      default: () => [],
    },
    containerClass: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      shownButtons: [],
      hiddenButtons: [],
    }
  },

  directives: {
    resize: {
      bind(el, binding) {
        window.addEventListener('resize', binding.value)
      },
      unbind(el, binding) {
        window.removeEventListener('resize', binding.value)
      },
    },
  },

  watch: {
    items(newItems) {
      this.shownButtons = newItems.filter((el) => el.visible !== false && el.hidden !== true)
      this.hiddenButtons = newItems.filter((el) => el.visible !== false && el.hidden === true)
      this.checkSize()
    },
  },

  mounted() {
    this.shownButtons = this.items.filter((el) => el.visible !== false && el.hidden !== true)
    this.hiddenButtons = this.items.filter((el) => el.visible !== false && el.hidden == true)
    this.checkSize()
  },

  methods: {
    async checkSize() {
      this.$nextTick(() => {
        const defaultContainerWidth = 1290
        let containerWidth = this.$refs.buttonList.clientWidth

        if (containerWidth === 0) {
          containerWidth = defaultContainerWidth
        }

        let totalButtonWidth = 50
        const shownButtons = []
        let hiddenButtons = []
        const hiddenOnly = this.hiddenButtons.filter((el) => el.hidden === true)

        for (let i = 0; i < this.shownButtons.length; i++) {
          const buttonWidth = this.$refs.button[i].$el.offsetWidth
          totalButtonWidth += buttonWidth + 15
          const button = { ...this.shownButtons[i], width: buttonWidth }

          if (totalButtonWidth > containerWidth) {
            hiddenButtons.push(button)
          } else {
            shownButtons.push(button)
          }
        }

        const notHidden = this.hiddenButtons.filter((el) => el.hidden !== true)

        if (notHidden.length > 0) {
          hiddenButtons = [...hiddenButtons, ...notHidden]
          const firstButton = hiddenButtons[0]

          if (totalButtonWidth + firstButton.width + 40 < containerWidth) {
            shownButtons.push(firstButton)
            hiddenButtons.splice(0, 1)
          }
        }

        this.shownButtons = shownButtons
        this.hiddenButtons = [...hiddenButtons, ...hiddenOnly]
      })
    },
  },
}
</script>

<style scoped lang="scss">
.tool-bar-container {
  gap: 6px;
}
</style>

<style lang="scss">

.t-menu-btn {
  padding-top: 0px;
  padding-bottom: 0px;
  line-height: 1;
}

.t-menu-icon {
  font-size: 24px;
}

</style>