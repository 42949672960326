import _ from 'lodash'
import { ActionContext } from 'vuex'
import { IState } from '@/store/service/state'
import { commonState } from '../service/state'
import { commonMutations } from '../service/mutations'
import { commonGetters } from '../service/getters'
import { findAll, findByPk, createItem, updateItem, deleteItem, addNewItem, resetState } from '../service/actions'
import axios from 'axios'

import BasicObject from '@/dto/ScheduledJob.json'
const apiUrl = 'scheduled_jobs'

export const state = Object.assign({}, _.cloneDeep(commonState))

export const mutations = { ...commonMutations }

export const actions = {
  async findAll(context: ActionContext<IState, any>, payload: any) {
    return findAll(context, apiUrl, payload)
  },

  async findByPk(context: ActionContext<IState, any>, payload: any) {
    return findByPk(context, apiUrl, payload)
  },

  async create(context: ActionContext<IState, any>, payload: any) {
    return createItem(context, apiUrl, payload)
  },

  async update(context: ActionContext<IState, any>, payload: any) {
    return updateItem(context, apiUrl, payload)
  },

  async delete(context: ActionContext<IState, any>, payload: any) {
    return deleteItem(context, apiUrl, payload)
  },

  async addNew(context: ActionContext<IState, any>, payload: any) {
    return addNewItem(context, { basicObject: BasicObject }, payload, beforeAddNew)
  },

  async executeManually(context: ActionContext<IState, any>, payload: any) {
    return axios
      .post(`/${apiUrl}/execute_manually`, payload)
      .then((response: any) => {
        return response
      })
      .catch((error: Error) => {
        throw error
      })
  },

  resetState(context: ActionContext<IState, any>) {
    resetState(context)
  },
}

export const getters = { ...commonGetters }

function beforeAddNew(viewData: any) {
  viewData.tabsIndex = 0
}
