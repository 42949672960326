import axios from 'axios'
import _ from 'lodash'
import Vue from 'vue'
import definedBoxes from '@/constants/definedBoxes'

const apiURL = 'mailbox'

const initState = {
  mailboxView: {
    list: [],
    emailAccount: null,
    onlineMode: false,
    boxes: [],
    currentBox: null,
    currentLabel: null,
    settings: {
      scrollTop: null,
      scrollTopDetail: null,
    },
    filters: {
      searchStr: '',
      period: [null, null],
      from: '',
      to: '',
      subject: '',
      body: '',
      hasLinks: null,
      processed: null,
      counterparty: null,
      contactPerson: null,
    },
    total: 0,
    limit: 20,
    page: 1,
  },
  objectViews: [],
}

export const state = Object.assign({}, _.cloneDeep(initState))

export const mutations = {
  setEmailAccount(state: any, emailAccount: any) {
    state.mailboxView.emailAccount = emailAccount
  },

  setObjectProperty(state: any, payload: any) {
    const index = state.objectViews.findIndex((el: any) => el.viewId === payload.viewId)
    if (index > -1) {
      Vue.set(state.objectViews[index].object, payload.property, payload.value)
    }
  },

  setObjectProperties(state: any, payload: any) {
    const index = state.objectViews.findIndex((el: any) => el.viewId === payload.viewId)
    if (index > -1) {
      Object.keys(payload.props).forEach((key) => {
        Vue.set(state.objectViews[index].object, key, payload.props[key])
      })
    }
  },

  setBoxes(state: any, boxes: any) {
    state.mailboxView.boxes = boxes
  },

  setFilters(state: any, filter: any) {
    Object.keys(filter).forEach((key) => {
      Vue.set(state.mailboxView.filters, key, filter[key])
    })
  },

  setSettingsProperty(state: any, setting: any) {
    Object.keys(setting).forEach((key) => {
      Vue.set(state.mailboxView.settings, key, setting[key])
    })
  },

  setObjectSettingsProperties(state: any, payload: any) {
    const viewIndex = state.objectViews.findIndex((el: any) => el.viewId === payload.viewId)

    if (viewIndex > -1) {
      Object.keys(payload.settings).forEach((key) => {
        Vue.set(state.objectViews[viewIndex].settings, key, payload.settings[key])
      })
    }
  },

  setMailboxProperty(state: any, payload: any) {
    Object.keys(payload).forEach((key) => {
      Vue.set(state.mailboxView, key, payload[key])
    })
  },

  resetState(state: any) {
    Object.assign(state, _.cloneDeep(initState))
  },

  addObjectView(state: any, view: any) {
    const existView = state.objectViews.find((el: any) => el.viewId === view.viewId)

    if (existView === undefined) {
      state.objectViews.push(view)
    }
  },

  delObjectView(state: any, viewId: string) {
    for (const [i, v] of state.objectViews.entries()) {
      if (v.viewId === viewId) {
        state.objectViews.splice(i, 1)
        break
      }
    }
  },
}

export const actions = {
  async getMailboxes({ commit }: any, payload: any) {
    try {
      const response = await axios.get(`/${apiURL}/info`, payload)

      if (response.status === 200) {
        const boxes = response.data

        for (const box of boxes) {
          const defindBox = definedBoxes.find((el) => box.attribs.includes(el.name) || el.name === box.name)

          if (defindBox) {
            box.sort = defindBox.sort
            box.icon = defindBox.icon
            box.title = defindBox.title
          } else {
            box.sort = 99
            box.icon = 'ri-folder-2-line'
            box.title = null
          }
        }

        boxes.sort((a: any, b: any) => a.sort - b.sort)

        commit('setBoxes', boxes)

        return response
      } else {
        commit('setBoxes', [])
        return response
      }
    } catch (error) {
      commit('setBoxes', [])
      throw new Error(error)
    }
  },

  async findAll({ commit, state }: any, payload: any) {
    try {
      let response

      if (state.mailboxView.currentBox.name === 'Sent' || state.mailboxView.currentBox.name === 'Drafts') {
        response = await axios.get(`/outgoing_emails`, payload)
      } else {
        response = await axios.get(`/${apiURL}`, payload)
      }

      if (response.status === 200) {
        commit('setMailboxProperty', { list: response.data.messages, total: response.data.total })

        return response
      } else {
        commit('setMailboxProperty', { list: [], total: 0, page: 1 })
        return response
      }
    } catch (error) {
      commit('setMailboxProperty', { list: [], total: 0, page: 1 })
      throw error
    }
  },

  async findByPk({ commit }: any, payload: any) {
    return axios
      .get(`/incoming_emails/${payload.params.id}`, payload)
      .then((response) => {
        if (!payload.noCommit) {
          if (response.status === 200) {
            commit('addObjectView', { viewId: payload.params.id, object: response.data, settings: { scrollTop: 0 } })
            return true
          }

          return false
        } else {
          return response
        }
      })
      .catch((error) => {
        throw error
      })
  },

  async findBySeq({ commit }: any, payload: any) {
    return axios
      .get(`/${apiURL}/seq/${payload.params.id}`, payload)
      .then((response) => {
        if (response.status === 200) {
          if (!payload.noCommit) {
            commit('addObjectView', {
              viewId: `${payload.params.id}${payload.params.emailAccountId}`,
              object: { ...response.data, emailAccountId: payload.params.emailAccountId },
            })
            return true
          } else {
            return response
          }
        }

        return false
      })
      .catch((error) => {
        console.error(error)
        return false
      })
  },

  async setFlag(context: any, payload: any) {
    return axios
      .post(`/${apiURL}/flag`, payload)
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  },

  async moveEmail(context: any, payload: any) {
    return axios
      .post(`/${apiURL}/move`, payload)
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  },

  async setSeen(context: any, payload: any) {
    return axios
      .post(`/${apiURL}/seen`, payload)
      .then((response) => {
        if (response.status === 200) {
          return true
        }
        return false
      })
      .catch((error) => {
        console.error(error)
        return false
      })
  },

  async getCIEmails(context: any, payload: any) {
    return axios
      .get(`/${apiURL}/contacts`, payload)
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  },

  async setUnseen(context: any, payload: any) {
    return axios
      .post(`/${apiURL}/unseen`, payload)
      .then((response) => {
        if (response.status === 200) {
          return true
        }
        return false
      })
      .catch((error) => {
        console.error(error)
        return false
      })
  },

  resetState({ commit }: any) {
    commit('resetState')
  },
}

export const getters = {
  emailAccount(state: any) {
    return state.mailboxView.emailAccount
  },
  mailboxView(state: any) {
    return state.mailboxView
  },
  mailboxViewList(state: any) {
    return state.mailboxView.list
  },
  mailboxViewFilters(state: any) {
    return state.mailboxView.filters
  },
  mailboxViewBoxes(state: any) {
    return state.mailboxView.boxes
  },
  mailboxViewCurrentBox(state: any) {
    return state.mailboxView.currentBox
  },
  mailboxViewCurrentLabel(state: any) {
    return state.mailboxView.currentLabel
  },
  objectView: (state: any) => (viewId: any) => {
    return state.objectViews.find((el: any) => el.viewId === viewId)
  },
}
