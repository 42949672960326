<script>
import _ from 'lodash'
import { h } from 'vue'
import moment from 'moment'
import { uuid } from 'vue-uuid'
import { mapGetters } from 'vuex'
import appConfig from '@/app.config'
import Loading from '@/components/common/loading'
import Toolbar from '@/components/common/toolbar'
import QuickFilter from '@/components/common/quick-filter'
import AdvancedFilter from '@/components/common/advanced-filter'
import UserViewSettings from '@/components/common/user-view-settings'

export default {
  components: {
    Loading,
    Toolbar,
    QuickFilter,
    AdvancedFilter,
    UserViewSettings,
  },

  page() {
    return {
      title: this.$t('common.selectPossition'),
      meta: [{ name: 'description', content: appConfig.description }],
    }
  },

  props: {
    value: {
      type: [Object, Array],
      default: null,
    },
    valueType: {
      type: String,
      require: true,
    },
    label: {
      type: String,
      default: 'name',
    },
    addBtn: {
      type: Boolean,
      default: false,
    },
    selectView: {
      type: String,
      default: null,
    },
    filter: {
      type: [Object, Promise],
      default: null,
    },
    listParams: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      title: this.$t('common.selectPossition'),
      initEnd: false,
      loading: false,
      items: [],
      fields: [],
      list: [],
      showQuickFilters: false,
      busy: false,
      searchStr: '',
      viewSettings: null,
      editViewSettings: false,
      userViewSettings: null,
      advancedFilter: {
        editMode: false,
        currentFilter: null,
        currentIndex: -1,
      },
      filters: {
        quick: [],
        advanced: [],
      },
      folders: {
        list: [],
        expanded: [],
      },
      settings: {},
      sortBy: null,
      sortDesc: null,
      onCreate: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      objectData: {},
      localFilter: {},
      attrs: {},
    }
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      currentLanguage: 'auth/currentLanguage',
    }),

    toolbarItems() {
      const commonItems = [
        {
          id: 'q-filter-btn',
          title: this.$t('common.quickFilters'),
          icon: 'ri-filter-line',
          presentation: 'icon',
          variant: 'outline-secondary',
          size: 'sm',
          click: () => (this.showQuickFilters = !this.showQuickFilters),
          visible: this.filters.quick.length > 0,
          tooltip: this.$t('common.quickFilters'),
        },
        {
          id: 'search-btn',
          title: this.$t('commands.advancedSearch'),
          icon: 'ri-search-line',
          presentation: 'icon',
          variant: 'outline-secondary',
          size: 'sm',
          click: () => (this.advancedFilter.editMode = true),
          disabled: !this.objectData.model,
          tooltip: this.$t('commands.advancedSearch'),
        },
        {
          id: 'edit-settings-btn',
          title: this.$t('commands.changeView'),
          icon: 'ri-settings-3-line mr-1',
          presentation: 'icon',
          variant: 'outline-secondary',
          size: 'sm',
          click: () => (this.editViewSettings = true),
          disabled: !this.viewSettings,
          tooltip: this.$t('commands.changeView'),
          hidden: true,
        },
      ]

      if (Array.isArray(this.viewSettings?.toolbar)) {
        for (const toolbarItem of this.viewSettings?.toolbar) {
          const tItem = { ...toolbarItem }
          if (tItem.type !== 'dropdown') {
            tItem.click = () => this.externalFunc(toolbarItem.onClick)
          } else {
            for (const listItem of tItem.items) {
              listItem.click = () => this.externalFunc(listItem.onClick)
            }
          }
          commonItems.push(tItem)
        }
      }

      return commonItems
    },

    getUuid() {
      return uuid.v4
    },
  },

  render() {
    const pageToolbar = this.renderPageToolbar()
    const pageItems = this.renderPageItems(this.items)

    const pageBody = h(
      'b-modal',
      {
        attrs: {
          id: 'select-view',
          size: 'xl',
          title: this.title,
          dialogClass: 'pl-0 pl-md-5',
          noCloseOnBackdrop: true,
        },
        on: {
          ok: () => this.handleOk(),
          cancel: this.handleCancel,
          close: this.handleClose,
        },
      },
      [pageToolbar, pageItems, h('template', { slot: 'modal-cancel' }, this.$t('commands.cancel'))]
    )

    const pageComponents = this.renderPageComponents()
    const renderArray = []

    renderArray.push(pageBody)
    renderArray.push(pageComponents)

    return h('div', renderArray)
  },

  async created() {
    if (this.filter?.constructor?.name === 'Promise') {
      this.localFilter = await this.filter
    } else {
      this.localFilter = this.filter
    }

    await this.initialize()

    this.initEnd = true
  },

  async mounted() {
    this.$bvModal.show('select-view')
  },

  methods: {
    renderPageToolbar() {
      const toolbar = h('toolbar', {
        attrs: {
          items: this.toolbarItems,
        },
      })

      const toolbarCol = h(
        'div',
        {
          class: 'col',
        },
        [toolbar]
      )

      const serchField = h('b-form-input', {
        props: {
          id: 'search-input',
          value: this.searchStr,
          type: 'search',
          autofocus: true,
          size: 'sm',
          debounce: 500,
          placeholder: this.$t('common.search'),
        },
        on: {
          input: (event) => {
            this.onChangeFilter(event)
          },
        },
      })

      const searchCol = h(
        'div',
        {
          class: 'col-xl-3',
        },
        [serchField]
      )

      return h('div', { class: 'row' }, [toolbarCol, searchCol])
    },

    renderPageItems(viewItems, noGreed = false) {
      const items = []

      for (const item of viewItems) {
        if (item.visible === false) {
          continue
        }

        if (item.newRow === true && item.noGreed !== true) {
          items.push(
            h('div', {
              key: `b-${item.name}`,
              class: 'w-100',
            })
          )
        }

        let pageItem

        let childItems = null
        if (item.items && item.items?.length > 0) {
          childItems = this.renderPageItems(item.items, item.type === 'tabs')
        }

        if (item.type === 'advancedComponent') {
          pageItem = h(item.component, {
            class: item.classes,
            style: item.styles,
            attrs: this.mapComponentAttrs(item),
            on: this.mapComponentActions(item),
          })
        } else if (item.type === 'dynamic') {
          if (this.attrs.additionalParameters && item.tagName === '[additional-parameters]') {
            pageItem = []
            for (const row of this.attrs.additionalParameters) {
              const object = this.renderAdditionalParameters(row)
              pageItem.push(
                h(
                  'b-form-group',
                  {
                    class: item.classes,
                    style: item.styles,
                    attrs: {
                      horizontal: true,
                      labelCols: 3,
                      contentCols: null,
                      label: object.name,
                    },
                  },
                  [object.pageField]
                )
              )
            }
          } else {
            pageItem = h(
              item.tagName,
              {
                ref: item.tagRef ? item.tagRef : null,
                class: item.classes,
                style: item.styles,
                attrs: this.mapComponentAttrs(item),
                on: this.mapComponentActions(item),
              },
              [childItems]
            )
          }
        } else if (item.type === 'dynamicList') {
          if (!this.listView.views.find((el) => el.viewId === item.id)) {
            this.addItem({
              items: this.listView.views,
              item: {
                viewId: item.id,
                list: [],
                fields: [],
                total: 0,
                page: 1,
                limit: 20,
                filters: {
                  searchStr: '',
                  advanced: [],
                  quick: [],
                },
                sort: { sortBy: null, sortDesc: false },
              },
            })
          }

          const listView = this.listView.views.find((el) => el.viewId === item.id)

          pageItem = h('dynamic-list', {
            ref: item.tagRef ? item.tagRef : null,
            class: item.classes,
            style: item.styles,
            attrs: {
              appObjectId: item.appObjectId,
              detailView: item.detailView,
              fields: item.items,
              listView,
              ...this.mapComponentAttrs(item),
            },
            on: this.mapComponentActions(item),
          })
        } else if (item.type === 'viewList') {
          const viewListItems = []

          const qFilters = []
          this.filters.quick
            .filter((el) => el.hidden !== true)
            .map((gFilter, idx) => {
              qFilters.push(
                h('div', { class: 'col', key: idx }, [
                  h('quick-filter', {
                    attrs: {
                      filter: gFilter,
                    },
                    on: {
                      'change-filter': this.onChangeQuickFilter,
                    },
                  }),
                ])
              )
            })

          const qFiltersCol = h(
            'b-collapse',
            {
              id: 'q-filters-list',
              attrs: {
                visible: this.showQuickFilters,
              },
              on: {
                input: (event) => {
                  this.showQuickFilters = event
                },
              },
            },
            [h('div', { class: 'row row-cols-lg-2 row-cols-1' }, qFilters)]
          )

          viewListItems.push(h('div', { class: 'row' }, [h('div', { class: 'col' }, [qFiltersCol])]))

          if (this.filters.advanced.length > 0) {
            const advancedFilters = []

            this.filters.advanced.map((advancedFilter, idx) => {
              advancedFilters.push(
                h(
                  'b-form-tag',
                  {
                    class: 'mr-1',
                    key: idx,
                    attrs: {
                      variant: 'outline-secondary',
                    },
                    on: {
                      remove: () => this.onRemoveAdvancedFilter(idx),
                    },
                  },
                  [
                    h(
                      'a',
                      {
                        attrs: {
                          href: 'javascript:void(0);',
                        },
                        on: {
                          click: () => this.onEditAdvancedFilter(advancedFilter, idx),
                        },
                      },
                      advancedFilter.presentation
                    ),
                  ]
                )
              )
            })

            const advancedFilterCol = h('div', { class: 'advanced-filter' }, advancedFilters)

            viewListItems.push(h('div', { class: 'row mb-2' }, [h('div', { class: 'col' }, [advancedFilterCol])]))
          }

          const foldersList = []
          const foldersItem = []
          if (this.objectData.hierarchic) {
            this.renderFoldersList(this.folders.list, foldersList)

            foldersItem.push(
              h('b-card', { attrs: { noBody: true } }, [
                h('b-card-header', { class: 'folders-header py-0 px-2 d-flex justify-content-between align-items-center mb-1' }, [
                  h('div', { class: 'folders-title' }, this.$t('table.folders')),
                ]),
                h('div', { class: 'folders-conteiner' }, foldersList),
              ])
            )
          }

          const viewList = h('b-table', {
            ref: 'itemsList',
            props: {
              hover: true,
              striped: true,
              class: ['min-w-800', 'mb-1'],
              small: true,
              stickyHeader: '70vh',
              selectable: true,
              busy: this.busy,
              selectMode: 'range',
              selectedVariant: 'secondary',
              noLocalSorting: true,
              items: this.list,
              fields: this.initFields(item.items),
              filter: this.localFilter,
              sortBy: this.sortBy,
              sortDesc: this.sortDesc,
              perPage: this.perPage,
              currentPage: 1,
              tbodyTrClass: this.rowClass,
            },
            on: {
              'row-selected': this.onRowSelected,
              'sort-changed': this.onSortingChanged,
            },
            scopedSlots: {
              'table-busy': () =>
                h('div', { class: 'text-center text-primary my-2' }, [
                  h('b-spinner', { class: 'align-middle mr-2' }),
                  h('strong', {}, [this.$t('common.loading') + '...']),
                ]),
              'table-colgroup': (scope) => {
                const fields = []

                for (const field of scope.fields) {
                  fields.push(
                    h('col', {
                      style: {
                        width: field.width ? `${field.width}%` : null,
                      },

                      key: field.key,
                    })
                  )
                }

                return fields
              },
              'cell()': (data) => {
                const tableColl = []

                if (data.field.first) {
                  let firstClass
                  if (this.objectData.type === 'document') {
                    firstClass = data.item.markedToDelete
                      ? 'ri-close-circle-line mr-1 text-danger'
                      : data.item.isConfirmed
                      ? 'ri-checkbox-circle-line mr-1 text-info'
                      : 'ri-checkbox-blank-circle-line mr-1 text-info'
                  } else {
                    if (data.item.isFolder) {
                      firstClass = 'ri-folder-line mr-1 text-info'
                    } else {
                      firstClass = 'ri-arrow-right-s-line mr-1 text-info'
                    }
                  }

                  tableColl.push(
                    h('span', {
                      class: firstClass,
                      attrs: {
                        ariaHidden: true,
                      },
                    })
                  )

                  tableColl.push(
                    h(
                      'span',
                      {
                        class: data.item.markedToDelete ? 'text-danger' : 'text-info',
                      },
                      this.cellValue(data)
                    )
                  )
                } else {
                  tableColl.push(
                    h(
                      'span',
                      {
                        class: this.getFieldConditionalDesign(data.field, data.item),
                      },
                      this.cellValue(data)
                    )
                  )
                }

                return tableColl
              },
            },
          })

          if (this.objectData.hierarchic) {
            viewListItems.push(
              h('div', { class: 'row' }, [h('div', { class: 'col-3 pr-0' }, [foldersItem]), h('div', { class: 'col overflow-x-auto' }, [viewList])])
            )
          } else {
            viewListItems.push(h('div', { class: 'row' }, [h('div', { class: 'col overflow-x-auto' }, [viewList])]))
          }

          const paginationItem = h('b-pagination', {
            class: 'my-0',
            attrs: {
              value: this.currentPage,
              totalRows: this.totalRows,
              perPage: this.perPage,
              align: 'right',
            },
            on: {
              input: (event) => {
                this.onChangePage(event)
              },
            },
          })
          viewListItems.push(h('div', { class: 'row' }, [h('div', { class: 'col' }, [paginationItem])]))

          pageItem = viewListItems
        } else if (item.type === 'input') {
          const pageField = h('b-form-input', {
            attrs: {
              id: `item-${item.name}`,
              value: this.getVariablePath(item.dataPath),
              type: item.inputType,
              placeholder: item.placeholder,
              size: 'sm',
              disabled: item.disabled,
              ...this.mapComponentAttrs(item),
            },
            on: this.mapComponentActions(item),
          })

          pageItem = h(
            'b-form-group',
            {
              class: item.classes,
              style: item.styles,
              attrs: {
                horizontal: item.labelPosition === 'left' ? true : item.labelPosition === 'top' ? false : true,
                labelCols: item.labelPosition === 'top' ? 0 : item.labelCols && item.labelCols !== 0 ? item.labelCols : 3,
                contentCols: item.contentCols && item.contentCols !== 0 ? item.contentCols : null,
                label: item.label,
              },
            },
            [pageField]
          )
        } else if (item.type === 'checkbox') {
          const pageField = h('b-form-checkbox', {
            attrs: {
              id: `item-${item.name}`,
              size: 'sm',
              class: 'mt-1',
              disabled: item.disabled,
              switch: item.switch,
              ...this.mapComponentAttrs(item),
            },
            on: this.mapComponentActions(item),
          })

          pageItem = h(
            'b-form-group',
            {
              class: item.classes,
              style: item.styles,
              attrs: {
                horizontal: item.labelPosition === 'left' ? true : item.labelPosition === 'top' ? false : true,
                labelCols: item.labelPosition === 'top' ? 0 : item.labelCols && item.labelCols !== 0 ? item.labelCols : 3,
                label: item.label,
              },
            },
            [pageField]
          )
        } else if (item.type === 'select') {
          if (item.selectType === 'object') {
            const pageField = h('f-select', {
              attrs: {
                id: `item-${item.name}`,
                value: this.getValueByPath(item),
                selectBtn: item.selectBtn || false,
                openBtn: item.openBtn || false,
                hyperLink: item.hyperLink,
                multiType: false,
                valueType: item.ref,
                detailView: item.detailView,
                valueTypes: [],
                placeholder: item.placeholder,
                filter: {},
                disabled: item.disabled,
                required: item.required,
                state: null,
              },
              on: {
                input: (value) => {
                  this.onChangeItemValue(value, item)
                },
                ...this.mapComponentActions(item),
              },
            })

            if (item.labelPosition === 'none') {
              pageItem = h('div', [pageField])
            } else {
              pageItem = h(
                'b-form-group',
                {
                  class: item.classes,
                  style: item.styles,
                  attrs: {
                    horizontal: item.labelPosition === 'left' ? true : item.labelPosition === 'top' ? false : true,
                    labelCols: item.labelPosition === 'top' ? 0 : item.labelCols && item.labelCols !== 0 ? item.labelCols : 3,
                    contentCols: item.contentCols && item.contentCols !== 0 ? item.contentCols : null,
                    label: item.label,
                  },
                },
                [pageField]
              )
            }
          }
        } else if (item.type === 'tabs') {
          pageItem = h(
            'b-tabs',
            {
              class: item.classes,
              style: item.styles,
              attrs: {
                'content-class': 'mt-2',
                ...this.mapComponentAttrs(item),
              },
              on: this.mapComponentActions(item),
            },
            [childItems]
          )
        } else if (item.type === 'group') {
          const pageCol = h(
            'div',
            {
              class: 'col pl-0 pr-0',
            },
            [childItems]
          )

          pageItem = h(
            'div',
            {
              class: `'row ${item.classes}`,
              style: item.styles,
              attrs: {
                ...this.mapComponentAttrs(item),
              },
              on: this.mapComponentActions(item),
            },
            [pageCol]
          )
        } else if (item.type === 'tab') {
          pageItem = h(
            'b-tab',
            {
              class: item.classes,
              style: item.styles,
              attrs: {
                title: item.label,
                ...this.mapComponentAttrs(item),
              },
              on: this.mapComponentActions(item),
            },
            [childItems]
          )
        } else if (item.type === 'text') {
          pageItem = h(
            'span',
            {
              class: item.classes,
              style: item.styles,
            },
            item.dataLinked === true ? this.getVariablePath(item.dataPath) : item.defaultValue
          )
        } else if (item.type === 'button') {
          pageItem = h(
            'b-button',
            {
              class: item.classes,
              style: item.styles,
              attrs: {
                variant: item.variant,
                disabled: item.disabled,
                pill: item.pill,
                squared: item.squared,
                block: item.block,
                size: 'sm',
              },
              on: this.mapComponentActions(item),
            },
            item.label
          )
        }

        if (item.noGreed !== true) {
          if (item.tagName === '[additional-parameters]') {
            if (Array.isArray(pageItem)) {
              for (const pageItemItem of pageItem) {
                items.push(
                  h('div', {
                    class: 'w-100',
                  })
                )
                items.push(
                  h(
                    'div',
                    {
                      class: 'col-6',
                    },
                    [pageItemItem]
                  )
                )
              }
            }
          } else {
            items.push(
              h(
                'div',
                {
                  key: item.name,
                  class: `${item.fieldCols && item.fieldCols !== 0 ? `col-${item.fieldCols}` : 'col'}${
                    item.itemOffset && item.itemOffset !== 0 ? ` offset-${item.itemOffset}` : ''
                  }`,
                },
                [pageItem]
              )
            )
          }
        } else {
          items.push(pageItem)
        }
      }

      return noGreed === true
        ? items
        : h(
            'div',
            {
              class: 'row',
            },
            [items]
          )
    },

    renderFoldersList(folders, foldersList) {
      for (const folder of folders) {
        let expandBtn
        if (folder.childs.length > 0) {
          expandBtn = h('a', { attrs: { href: '#' }, on: { click: () => this.changeFolderExpanded(folder) } }, [
            h('i', { class: `mr-1 ${folder.expanded ? 'ri-arrow-down-s-line' : 'ri-arrow-right-s-line'}` }),
          ])
        } else {
          expandBtn = h('a', { attrs: { href: '#' } }, [h('i', { class: 'mr-1 ri-subtract-line' })])
        }

        const folderIcon = h('i', { class: `${folder.allFolders ? 'ri-folders-line' : 'ri-folder-line'} mr-1` })
        const folderInfo = h('span', folder.presentation)
        const folderData = h(
          'div',
          {
            class: 'w-100',
            on: {
              dblclick: () => this.onSelectFolder(folder),
              click: () => this.onClickFolder(folder),
            },
          },
          [folderIcon, folderInfo]
        )

        const folderCol = h('div', { class: 'col' }, [
          h('div', { class: `d-flex folder-item${this.settings.selectedFolder?.id === folder.id ? ' item-active' : ''}` }, [expandBtn, folderData]),
        ])

        const folderRow = h('div', [
          h(
            'div',
            {
              class: 'row',
            },
            [folderCol]
          ),
        ])

        foldersList.push(folderRow)

        if (folder.childs.length > 0) {
          const childItems = []
          this.renderFoldersList(folder.childs, childItems)

          const collapseItem = h('b-collapse', { class: 'ml-3', attrs: { id: folder.id, visible: folder.expanded } }, childItems)

          foldersList.push(collapseItem)
        }
      }
    },

    onSelectFolder(folder) {
      this.settings.selectedFolder = folder
      this.updateList()
    },

    onClickFolder(folder) {
      this.settings.selectedFolder = folder
      this.updateList()
    },

    changeFolderExpanded(folder) {
      folder.expanded = !folder.expanded

      if (folder.expanded === true) {
        this.folders.expanded.push(folder.id)
      } else {
        const index = this.folders.expanded.findIndex((el) => el === folder.idd)
        this.folders.expanded.splice(index, 1)
      }
    },

    renderPageComponents() {
      const components = []

      if (this.advancedFilter?.editMode) {
        components.push(
          h('advanced-filter', {
            attrs: {
              value: this.advancedFilter?.editMode,
              model: this.objectData.model,
              namespace: this.namespace,
              editFilter: this.advancedFilter?.currentFilter,
            },
            on: {
              input: (event) => {
                this.advancedFilter.editMode = event
              },
              'add-advanced-filter': this.onAddAdvancedFilter,
              'edit-advanced-filter': this.onEditAdvancedFilterEnd,
              'hide-advanced-filter': this.onHideAdvancedFilter,
            },
          })
        )
      }

      if (this.editViewSettings) {
        components.push(
          h('user-view-settings', {
            attrs: {
              value: this.editViewSettings,
              viewSettings: this.viewSettings,
              userViewSettings: this.userViewSettings,
            },
            on: {
              input: (event) => {
                this.editViewSettings = event
              },
              'update-settings': this.onUpdateViewSettings,
            },
          })
        )
      }

      components.push(
        h('loading', {
          attrs: {
            value: this.loading,
          },
          on: {
            input: (event) => {
              this.loading = event
            },
          },
        })
      )

      return components
    },

    renderAdditionalParameters(item) {
      let pageField

      if (item.parameter.type === 'object') {
        pageField = h('f-select', {
          attrs: {
            id: `item-${item.id}`,
            value: item.valueObject,
            selectBtn: false,
            openBtn: true,
            addBtn: true,
            filter: item.valueRef === 'additionalParametersValues' ? { additionalParameterId: item.parameterId } : {},
            valueType: item.valueRef,
            detailView: item.detailPath,
          },
          on: {
            input: (value) => this.onChangeAdditionalParameter(value, item),
          },
        })
      } else if (item.parameter.type === 'enum') {
        const enumName = this.$enums().find((el) => el.id === item.parameter.ref).name

        pageField = h('b-form-select', {
          attrs: {
            id: `item-${item.id}`,
            value: item.valueString,
            options: this.$enums(enumName),
            size: 'sm',
          },
          on: {
            change: (value) => this.onChangeAdditionalParameter(value, item),
          },
          scopedSlots: {
            first: () => h('b-form-select-option', { attrs: { value: '' } }, item.placeholder ? item.placeholder : this.$tc('common.emptyField')),
          },
        })
      } else {
        if (item.parameter.type === 'string') {
          pageField = h('b-input-group', {}, [
            h('b-form-input', {
              attrs: {
                id: `item-${item.id}`,
                value: item.valueString,
                type: 'text',
                size: 'sm',
                maxLength: JSON.parse(item.parameter.typeDetails).length,
              },
              on: {
                input: (value) => this.onChangeAdditionalParameter(value, item),
              },
            }),
          ])
        } else if (item.parameter.type === 'integer' || item.parameter.type === 'decimal') {
          let maxValue = null
          const typeDetails = JSON.parse(item.parameter.typeDetails)
          if (item.parameter.type === 'decimal') {
            const integerPartLength = typeDetails.precision - typeDetails.scale
            maxValue = '9'.repeat(integerPartLength) + '.' + '9'.repeat(typeDetails.scale)
            maxValue = parseFloat(maxValue)
          }

          pageField = h('b-form-input', {
            attrs: {
              id: `item-${item.id}`,
              value: item.valueNumber,
              type: 'number',
              size: 'sm',
              max: maxValue,
            },
            on: {
              input: (value) => this.onChangeAdditionalParameter(value, item),
            },
          })
        } else if (item.parameter.type === 'text' || item.parameter.type === 'json') {
          pageField = h('b-form-textarea', {
            attrs: {
              id: `item-${item.id}`,
              value: item.valueString,
              size: 'sm',
            },
            on: {
              input: (value) => this.onChangeAdditionalParameter(value, item),
            },
          })
        } else if (item.parameter.type === 'boolean') {
          pageField = h('b-form-checkbox', {
            attrs: {
              id: `item-${item.id}`,
              checked: item.valueBoolean,
              class: 'mt-1',
              switch: true,
            },
            on: {
              change: (value) => this.onChangeAdditionalParameter(value, item),
            },
          })
        } else if (item.parameter.type === 'date') {
          pageField = h('date-picker', {
            attrs: {
              id: `item-${item.id}`,
              value: this.formatDate(item.valueDate),
              type: JSON.parse(item.parameter.typeDetails).dateType,
              format: 'DD.MM.YYYY HH:mm:ss',
              showWeekNumber: true,
              size: 'sm',
            },
            on: {
              change: (value) => this.onChangeAdditionalParameter(value, item),
            },
          })
        }
      }

      return {
        pageField: pageField,
        name: item.parameter.name,
      }
    },

    onChangeAdditionalParameter(value, data) {
      const changedRow = _.cloneDeep(data)
      if (data.parameter.type === 'object') {
        if (value) {
          changedRow.valueString = value.id
          changedRow.valueObject = value
          changedRow.detailPath = data.detailPath
        } else {
          changedRow.valueString = ''
          changedRow.valueObject = null
          changedRow.detailPath = ''
        }
      }

      if (data.parameter.type === 'enum' || data.parameter.type === 'string' || data.parameter.type === 'json') {
        changedRow.valueString = value
      }

      if (data.parameter.type === 'integer' || data.parameter.type === 'decimal') {
        changedRow.valueNumber = value
      }

      if (data.parameter.type === 'boolean') {
        changedRow.valueBoolean = value
      }

      if (data.parameter.type === 'date') {
        changedRow.valueDate = value
      }

      this.$set(this.attrs.additionalParameters, data.rowNumber - 1, changedRow)
    },

    async initialize() {
      this.busy = true

      await this.initViewSettings()

      if (typeof this.onCreate === 'function') {
        await this.onCreate()
      }

      await this.initItems()

      this.initQuickFilters()
      if (this.objectData.hierarchic) {
        await this.initFolders()
        this.folderDetailPath = await this.getFolderDetailPath(this.objectData.id)
      } else {
        await this.updateList()
      }

      this.busy = false
    },

    async initViewSettings() {
      this.viewSettings = null
      await this.$store.dispatch('appObjects/findAll', { noCommit: true, params: { filter: { name: this.valueType } } }).then(async (response) => {
        if (response.data && response.data.length > 0) {
          this.objectData = response.data[0]
          if (this.selectView) {
            await this.$store.dispatch('viewSettings/findByPk', { noCommit: true, params: { id: this.selectView } }).then(async (responseSet) => {
              if (responseSet.data) {
                this.viewSettings = responseSet.data

                if (this.viewSettings.module && this.viewSettings.module?.length > 0) {
                  this.initViewModule()
                }
                await this.$store
                  .dispatch('userViewSettings/findAll', { noCommit: true, params: { filter: { viewId: this.viewSettings.id, userId: this.currentUser.id } } })
                  .then((responseUserSet) => {
                    if (responseUserSet.data && responseUserSet.data.length > 0) {
                      this.userViewSettings = responseUserSet.data[0]
                    } else {
                      this.userViewSettings = null
                    }
                  })
              }
            })
          } else {
            await this.$store
              .dispatch('viewSettings/findAll', {
                noCommit: true,
                params: { filter: { appObjectId: response.data[0].id, isDefault: true, viewType: 'select' } },
              })
              .then(async (responseSet) => {
                if (responseSet.data && responseSet.data.length > 0) {
                  this.viewSettings = responseSet.data[0]

                  if (this.viewSettings.module && this.viewSettings.module?.length > 0) {
                    this.initViewModule()
                  }
                  await this.$store
                    .dispatch('userViewSettings/findAll', { noCommit: true, params: { filter: { viewId: this.viewSettings.id, userId: this.currentUser.id } } })
                    .then((responseUserSet) => {
                      if (responseUserSet.data && responseUserSet.data.length > 0) {
                        this.userViewSettings = responseUserSet.data[0]
                      } else {
                        this.userViewSettings = null
                      }
                    })
                }
              })
          }
        }
      })
    },

    initViewModule() {
      try {
        const moduleData = eval(`(${this.viewSettings.module})`)

        if (moduleData) {
          if (moduleData.attrs) {
            for (const varKey in moduleData.attrs) {
              this.$set(this.attrs, varKey, moduleData.attrs[varKey])
            }
          }

          if (moduleData.methods) {
            for (const method in moduleData.methods) {
              this[method] = moduleData.methods[method]
            }
          }

          if (moduleData.onCreate) {
            this.onCreate = moduleData.onCreate
          }
        }
      } catch (error) {
        console.error(error)
      }
    },

    async initFolders() {
      const filterStr = {
        noCommit: true,
        params: {
          filter: { isFolder: true },
          sort: { sortBy: 'name', sortDesc: false },
          attributes: ['name', 'presentation', 'parentId'],
        },
      }

      this.addSystemFields(filterStr.params.attributes)

      this.busy = true

      const response = await this.$store.dispatch(`${this.valueType}/findAll`, filterStr)

      const folders = []

      folders.push({
        presentation: this.$tc('common.all'),
        name: this.$tc('common.all'),
        allFolders: true,
        expanded: false,
        childs: [],
      })

      if (response.data) {
        this.generateFoldersTree(response.data, folders, null)
      }

      this.folders.list = folders

      if (!this.settings.selectedFolder) {
        this.settings.selectedFolder = folders[0]
      }
      this.updateList()

      this.busy = false
    },

    generateFoldersTree(folders, resultChilds, parentId) {
      const lvlFolders = folders.filter((el) => {
        return el.parentId === parentId
      })

      for (const lvlFolder of lvlFolders) {
        const expanded = !!this.folders.expanded.find((el) => el === lvlFolder.id)

        const length = resultChilds.push({ ...lvlFolder, childs: [], expanded })
        this.generateFoldersTree(folders, resultChilds[length - 1].childs, lvlFolder.id)
      }
    },

    async getFolderDetailPath(appObjectId) {
      const viewResponse = await this.$store.dispatch('viewSettings/findAll', {
        noCommit: true,
        params: { filter: { viewType: 'detail', appObjectId, forFolder: true } },
      })

      if (viewResponse.data?.length > 0) {
        const viewId = viewResponse.data[0].id

        if (viewId) {
          const navResponse = await this.$store.dispatch(`navigation/findAll`, {
            noCommit: true,
            params: {
              filter: {
                viewId,
                viewType: 'detail',
              },
            },
          })

          if (navResponse && navResponse.data?.length > 0) {
            return navResponse.data[0].name
          }
        }
      }

      return
    },

    getFieldConditionalDesign(field, item) {
      let fieldClass

      if (field.classes) {
        fieldClass = field.classes
      }

      if (field.conditionalDesign) {
        fieldClass = fieldClass ? `${fieldClass} ${field.conditionalDesign}` : field.conditionalDesign
      }

      if (fieldClass && fieldClass?.length > 0) {
        const isObject = fieldClass.charAt(0) === '{' && fieldClass.charAt(fieldClass.length - 1) === '}'
        const isArray = fieldClass.charAt(0) === '[' && fieldClass.charAt(fieldClass.length - 1) === ']'
        const isBacktick = fieldClass.charAt(0) === '`' && fieldClass.charAt(fieldClass.length - 1) === '`'

        if (isObject || isArray) {
          fieldClass = eval('(' + fieldClass + ')')
        }

        if (isBacktick) {
          const functionName = fieldClass.slice(1, -1).trim()
          if (typeof this[functionName] === 'function') {
            fieldClass = this[functionName](item)
          } else {
            fieldClass = eval('(' + fieldClass + ')')
          }
        }
      }

      return fieldClass
    },

    async initItems() {
      try {
        if (this.viewSettings?.items) {
          const items = _.cloneDeep(this.viewSettings.items)
          this.items = items
        } else {
          this.items = this.getStandartItems()
        }
        await this.prepareItems(this.items)
      } catch (error) {
        console.error(error)
      }
    },

    getStandartItems() {
      let childItems = []
      if (this.objectData.type === 'catalog') {
        childItems.push({
          classes: null,
          dataType: 'string',
          defaultSortable: true,
          detailView: null,
          fieldPath: 'name',
          fieldType: 'text',
          fieldTypePath: null,
          format: '',
          icon: 'ri-subtract-line',
          id: uuid.v4(),
          label: 'Nazwa',
          lang: {
            label: {
              pl: 'Nazwa',
              nl: 'Name',
              en: 'Name',
              de: 'Name',
              fr: 'Name',
            },
          },
          multiType: false,
          name: 'name',
          placeholder: null,
          sortDisabled: false,
          sortable: true,
          styles: null,
          type: 'objectField',
          visible: true,
          width: null,
        })
      } else if (this.objectData.type === 'document') {
        childItems = [
          {
            classes: null,
            dataType: 'integer',
            defaultSortable: false,
            detailView: null,
            fieldPath: 'number',
            fieldType: 'number',
            fieldTypePath: null,
            format: '',
            icon: 'ri-subtract-line',
            id: uuid.v4(),
            label: 'Numer',
            lang: {
              label: {
                de: 'Nummer',
                en: 'Number',
                es: 'Numer',
                fr: 'Numéro',
                it: 'Numer',
                nl: 'Number',
                pl: 'Numer',
                ua: 'Номер',
              },
            },
            multiType: false,
            name: 'number',
            placeholder: null,
            sortDisabled: false,
            sortable: false,
            styles: null,
            type: 'objectField',
            visible: true,
            width: null,
          },
          {
            classes: null,
            dataType: 'string',
            defaultSortable: false,
            detailView: null,
            fieldPath: 'numberStr',
            fieldType: 'text',
            fieldTypePath: null,
            format: '',
            icon: 'ri-subtract-line',
            id: uuid.v4(),
            label: 'Numer (Str)',
            lang: {
              label: {
                de: 'Numer (Str)',
                en: 'Number (Str)',
                es: 'Numer (Str)',
                fr: 'Numéro (Str)',
                it: 'Numer (Str)',
                nl: 'Numer (Str)',
                pl: 'Numer (Str)',
                ua: 'Номер (Str)',
              },
            },
            multiType: false,
            name: 'numberStr',
            placeholder: null,
            sortDisabled: false,
            sortable: false,
            styles: null,
            type: 'objectField',
            visible: true,
            width: null,
          },
          {
            classes: null,
            dataType: 'date',
            defaultSortable: false,
            detailView: null,
            fieldPath: 'date',
            fieldType: 'date',
            fieldTypePath: null,
            format: 'DD.MM.YYYY HH:mm:ss',
            icon: 'ri-subtract-line',
            id: uuid.v4(),
            label: 'Data',
            lang: {
              label: {
                de: 'Data',
                en: 'Date',
                es: 'Data',
                fr: 'Date',
                it: 'Data',
                nl: 'Datum',
                pl: 'Data',
                ua: 'Дата',
              },
            },
            multiType: false,
            name: 'date',
            placeholder: null,
            sortDisabled: false,
            sortable: false,
            styles: null,
            type: 'objectField',
            visible: true,
            width: null,
          },
          // {
          //   classe: null,
          //   dataType: 'string',
          //   defaultSortable: false,
          //   detailView: null,
          //   fieldPath: 'prefixId',
          //   fieldType: 'text',
          //   fieldTypePath: null,
          //   format: '',
          //   icon: 'ri-subtract-line',
          //   id: uuid.v4(),
          //   label: 'Prefiks',
          //   lang: {
          //     label: {
          //       de: 'Prefiks',
          //       en: 'Prefix',
          //       es: 'Prefiks',
          //       fr: 'Préfixe',
          //       it: 'Prefiks',
          //       nl: 'Prefiks',
          //       pl: 'Prefiks',
          //       ua: 'Префікс',
          //     },
          //   },
          //   multiType: false,
          //   name: 'prefix',
          //   placeholder: null,
          //   sortDisabled: false,
          //   sortable: false,
          //   styles: null,
          //   type: 'objectField',
          //   visible: true,
          //   width: null,
          // },
        ]
      }

      return [
        {
          id: uuid.v4(),
          name: '_list',
          label: 'Lista',
          type: 'viewList',
          icon: 'ri-file-list-line',
          hasLabel: false,
          visible: true,
          newRow: true,
          updateList: false,
          updateSeconds: 60,
          fieldCols: 12,
          stales: null,
          classes: null,
          lang: {
            label: {
              pl: 'Lista',
              nl: 'List',
              en: 'List',
              de: 'List',
              fr: 'List',
            },
          },
          items: childItems,
        },
      ]
    },

    async prepareItems(items) {
      for (const item of items) {
        if (item.type === 'advancedComponent') {
          item.component = await this.initComponent(item.componentId)
        } else if (item.type === 'tablePart') {
          for (const field of item.items) {
            await this.initFieldData(field)
            field.key = field.name
          }
        } else if (item.type === 'select') {
          if (item.selectType === 'object' && item.appObjectId) {
            await this.$store
              .dispatch('appObjects/findByPk', {
                params: {
                  id: item.appObjectId,
                },
                noCommit: true,
              })
              .then((response) => {
                if (response.status === 200) {
                  item.ref = response.data.name
                }
              })
              .catch((error) => {
                console.error(error)
              })
          }
        } else {
          await this.initFieldData(item)
        }

        if (item.items) {
          await this.prepareItems(item.items)
        }
      }
    },

    async initComponent(componentsId) {
      try {
        const response = await this.$store.dispatch('components/findByPk', { noCommit: true, params: { id: componentsId } })
        const component = response.data

        const componentData = eval(`(${component.module})`)
        if (componentData) {
          return componentData
        }
      } catch (error) {
        return { name: 'no-name', template: '<div></div>' }
      }
    },

    async initFieldData(item) {
      if (item.dataType === 'enum' && item.ref) {
        item.options = this.$enums(item.ref)
      }

      if (item.multiType) {
        const valueTypes = []
        for (let i = 0; i < item.ref.length; i++) {
          const valueType = {
            valueType: item.ref[i],
            detailView: item.detailView[i],
          }
          valueTypes.push(valueType)
        }
        item.valueTypes = valueTypes
      }

      if (item.filter && item.filter !== '') {
        item.filter = await this.setFieldFilter(item.filter)
      }
    },

    async setFieldFilter(filter) {
      if (filter.startsWith('{') && filter.endsWith('}')) {
        return JSON.parse(filter)
      } else {
        if (this[filter]() instanceof Promise) {
          return await this[filter]()
        } else {
          return this[filter]()
        }
      }
    },

    initFields(items) {
      try {
        const fieldItems = this.userViewSettings ? this.userViewSettings.items : items

        if (fieldItems) {
          const listFields = fieldItems.filter((el) => {
            return el.visible === true
          })

          const fields = listFields.map((el) => {
            let label = el.label ? el.label : this.$tc(`table.${el.name}`)

            if (el.lang?.label && this.currentLanguage) {
              const currLocal = el.lang.label[this.currentLanguage.code]
              if (currLocal && currLocal !== '') {
                label = currLocal
              }
            }

            let key = el.name
            if (el.fieldType === 'object') {
              key = `${key}.presentation`
            }

            const field = { key, label, sortable: el.sortable, width: el.width, classes: el.classes, styles: el.styles, sortBy: el.sortBy }

            if (el.type === 'calculatedField') {
              field.calculatedField = true
              field.calculatedFunction = el.calculatedFunction
            }

            field.formatter = (value) => {
              if (el.dataType !== 'date' && el.dataType !== 'time' && el.format) {
                return !value ? '-' : eval(el.format) || '-'
              } else if (el.dataType == 'date' || el.dataType === 'time') {
                return !value ? '-' : moment(value).format(el.format)
              } else if (el.dataType == 'boolean') {
                return value === true ? this.$tc('common.yes') : this.$tc('common.no')
              } else if (el.dataType == 'enum') {
                return !value ? '-' : this.$enums(el.ref, value)
              } else return value
            }

            return field
          })

          if (fields.length > 0) {
            fields[0].first = true
          }
          this.fields = fields
          return fields
        }
      } catch (error) {
        console.error(error)
        this.fields = []
        return []
      }
    },

    initQuickFilters() {
      const viewSettings = this.userViewSettings ? this.userViewSettings : this.viewSettings ? this.viewSettings : null

      if (viewSettings && viewSettings.filters?.length > 0) {
        let quickFilters = _.cloneDeep(this.filters.quick)

        quickFilters = quickFilters.filter((el) => {
          const existFilter = viewSettings.filters.find((sFilter) => {
            return el.name === sFilter.name
          })

          return existFilter !== undefined
        })

        for (const filter of viewSettings.filters) {
          const existFilterIdx = quickFilters.findIndex((el) => {
            return el.name === filter.name
          })

          if (existFilterIdx === -1) {
            const newFilter = _.cloneDeep(filter)

            if (newFilter.dataType === 'enum' && newFilter.ref) {
              const enumValues = this.$enums(newFilter.ref)

              if (enumValues) {
                newFilter.options = enumValues
              }
            }

            if (!newFilter.operator) {
              if (newFilter.dataType === 'string') {
                newFilter.operator = 'iLike'
              } else {
                newFilter.operator = 'eq'
              }
            }

            quickFilters.push(newFilter)
          } else {
            quickFilters[existFilterIdx].label = filter.label
            quickFilters[existFilterIdx].hidden = filter.hidden
          }
        }

        const hasValues = quickFilters.find((el) => {
          return el.use && el.hidden !== true
        })

        this.showQuickFilters = hasValues !== undefined

        this.filters.quick = quickFilters
      } else {
        this.filters.quick = []
      }
    },

    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.markedToDelete) return 'table-danger text-danger striped'
    },

    async onChangeQuickFilter(filter) {
      if (filter) {
        const existFilterIdx = this.filters.quick.findIndex((el) => {
          return el.name === filter.name
        })

        if (existFilterIdx > -1) {
          this.filters.quick[existFilterIdx] = filter
        }
        this.loading = true
        await this.updateList()
        this.loading = false
      }
    },

    onAddAdvancedFilter(filter) {
      if (filter) {
        const existFilterIdx = this.filters.advanced.findIndex((el) => {
          return el.fieldName === filter.fieldName
        })

        const advancedFilters = _.cloneDeep(this.filters.advanced)

        if (existFilterIdx > -1) {
          advancedFilters[existFilterIdx] = filter
        } else {
          advancedFilters.push(filter)
        }

        this.filters.advanced = advancedFilters
        this.updateList()
      }
    },

    async getObjectDetailPath(appObjectId) {
      const viewResponse = await this.$store.dispatch('viewSettings/findAll', {
        noCommit: true,
        params: { filter: { viewType: 'detail', appObjectId } },
      })

      if (viewResponse.data?.length > 0) {
        const viewId = viewResponse.data[0].id

        if (viewId) {
          const navResponse = await this.$store.dispatch(`navigation/findAll`, {
            noCommit: true,
            params: {
              filter: {
                viewId,
                viewType: 'detail',
              },
            },
          })

          if (navResponse && navResponse.data?.length > 0) {
            return navResponse.data[0].name
          }
        }
      }

      return
    },

    getValueByPath(item) {
      if (item.dataPath) {
        if (item.dataPath.includes('attrs.')) {
          return this.attrs[item.dataPath.split('.')[1]]
        }
        return this[item.dataPath]
      }
    },

    onChangeItemValue(value, item) {
      if (item.dataPath) {
        if (item.dataPath.includes('attrs.')) {
          this.$set(this.attrs, item.dataPath.split('.')[1], value)
          this.$set(this.attrs, `${item.dataPath.split('.')[1]}Id`, value?.id ? value.id : null)
        } else {
          this[item.dataPath] = value
        }
      }
    },

    onEditAdvancedFilter(filter, index) {
      this.advancedFilter.currentIndex = index
      this.advancedFilter.currentFilter = filter
      this.advancedFilter.editMode = true
    },

    onEditAdvancedFilterEnd(filter) {
      if (this.advancedFilter.currentIndex > -1) {
        const advancedFilters = _.cloneDeep(this.filters.advanced)
        advancedFilters[this.advancedFilter.currentIndex] = filter
        this.filters.advanced = advancedFilters
        this.updateList()
      }
    },

    onRemoveAdvancedFilter(index) {
      const advancedFilters = _.cloneDeep(this.filters.advanced)
      advancedFilters.splice(index, 1)
      this.filters.advanced = advancedFilters
      this.updateList()
    },

    onHideAdvancedFilter() {
      this.advancedFilter.currentFilter = null
      this.advancedFilter.currentIndex = -1
    },

    async onUpdateViewSettings() {
      this.loading = true
      await this.updateUserViewSettings()
      await this.initItems()
      this.initQuickFilters()
      await this.updateList()
      this.loading = false
    },

    async updateUserViewSettings() {
      await this.$store
        .dispatch('userViewSettings/findAll', {
          noCommit: true,
          params: { filter: { viewId: this.viewSettings.id, userId: this.currentUser?.id } },
        })
        .then((response) => {
          if (response.data && response.data.length > 0) {
            this.userViewSettings = response.data[0]
          }
        })
    },

    async updateList() {
      const filterStr = {
        viewId: this.viewId,
        params: {
          filter: {},
          pagination: { page: this.currentPage, limit: this.perPage },
          sort: { sortBy: this.sortBy, sortDesc: this.sortDesc },
        },
      }

      if (this.objectData.hierarchic) {
        if (this.settings.selectedFolder) {
          filterStr.params.filter.parentId = this.settings.selectedFolder?.id
        }
      }

      if (this.searchStr) {
        filterStr.params.filter.searchStr = this.searchStr
      }

      if (this.period?.[0]) {
        filterStr.params.filter.period = this.period
      }

      if (this.localFilter) {
        for (const key in this.localFilter) {
          filterStr.params.filter[key] = this.localFilter[key]
        }
      }

      if (this.filters.advanced.length > 0) {
        const advanced = {}

        for (const filter of this.filters.advanced) {
          let filterValue = ''

          if (filter.operator === 'iLike' || filter.operator === 'notILike') {
            if (filter.position === 'begin') {
              filterValue = `${filter.value}%`
            } else if (filter.position === 'end') {
              filterValue = `%${filter.value}`
            } else {
              filterValue = `%${filter.value}%`
            }
          } else if (filter.operator === 'filled' || filter.operator === 'notFilled') {
            filterValue = null
          } else if (filter.fieldType === 'object') {
            if (filter.operator === 'inList' || filter.operator === 'notInList') {
              filterValue = filter.value.map((el) => {
                return el.id
              })
            } else {
              filterValue = filter.value ? filter.value.id : filter.value
            }
          } else {
            filterValue = filter.value
          }

          if (filter.operator) {
            if (filter.operator === 'filled') {
              advanced[filter.fieldPath] = { ['ne']: filterValue }
            } else if (filter.operator === 'notFilled') {
              advanced[filter.fieldPath] = { ['eq']: filterValue }
            } else {
              advanced[filter.fieldPath] = { [filter.operator]: filterValue }
            }
          } else {
            advanced[filter.fieldPath] = filterValue
          }
        }

        filterStr.params.filter.advanced = advanced
      }

      if (this.filters.quick.length > 0) {
        const quick = {}

        for (const filter of this.filters.quick) {
          if (filter.use !== true) {
            continue
          }

          let filterValue = ''

          if (filter.operator === 'iLike') {
            filterValue = `%${filter.value}%`
          } else if (filter.operator === 'filled' || filter.operator === 'notFilled') {
            filterValue = null
          } else if (filter.fieldType === 'object') {
            if (filter.operator === 'inList' || filter.operator === 'notInList') {
              filterValue = filter.value.map((el) => {
                return el.id
              })
            } else {
              filterValue = filter.value ? filter.value.id : filter.value
            }
          } else {
            filterValue = filter.value
          }

          if (filter.operator) {
            if (filter.operator === 'filled') {
              quick[filter.fieldPath] = { ['ne']: filterValue }
            } else if (filter.operator === 'notFilled') {
              quick[filter.fieldPath] = { ['eq']: filterValue }
            } else {
              quick[filter.fieldPath] = { [filter.operator]: filterValue }
            }
          } else {
            quick[filter.fieldPath] = filterValue
          }
        }

        filterStr.params.filter.quick = quick
      }

      if (this.listParams?.filters) {
        for (const listFilter of this.listParams?.filters) {
          const ownerFilter = { [listFilter.fieldPath]: { [listFilter.operator]: listFilter.value.id } }

          filterStr.params.filter.advanced = filterStr.params.filter.advanced ? Object.assign(filterStr.params.filter.advanced, ownerFilter) : ownerFilter
        }
      }

      if (this.fields.length > 0) {
        filterStr.params.attributes = this.fields.map((el) => {
          return el.key
        })

        this.addSystemFields(filterStr.params.attributes)
      }

      this.busy = true

      let list = []
      let page = this.currentPage
      let total = 0

      await this.$store
        .dispatch(`${this.valueType}/findAll`, filterStr)
        .then((response) => {
          if (response.status === 200) {
            const result = response.data

            if (result.rows) {
              list = result.rows
              total = result.count
            } else {
              list = response.data
              total = response.data.length
            }

            if (total > 0) {
              const pages = Math.ceil(total / this.perPage)
              if (pages < page) {
                page = pages
              }
            } else {
              page = 1
            }
          } else {
            page = 1
          }
        })
        .catch((error) => {
          console.error(error)
          page = 1
        })

      this.list = list
      this.totalRows = total
      this.currentPage = page

      this.busy = false
    },

    addSystemFields(attributes) {
      const sysFields = ['id', 'createdAt', 'updatedAt']

      if (this.objectData.type === 'catalog' || this.objectData.type === 'document') {
        sysFields.push('markedToDelete')
        sysFields.push('presentation')
      }

      if (this.objectData.type === 'catalog' && this.objectData.hierarchic) {
        sysFields.push('isFolder')
      }

      if (this.objectData.type === 'document') {
        sysFields.push('numberStr')

        if (this.objectData.confirmation) {
          sysFields.push('isConfirmed')
        }
      }

      for (const field of sysFields) {
        if (!attributes.includes(field)) {
          attributes.push(field)
        }
      }
    },

    mapComponentAttrs(field) {
      const attrs = { id: `item-${field.id}` }

      if (field.attrs) {
        for (const attr of field.attrs) {
          let value = null

          if (attr.isStatic === true) {
            value = attr.value
          } else {
            if (attr.value) {
              value = this.getVariablePath(attr.value)
            }
          }

          attrs[attr.name] = value
        }
      }

      return attrs
    },

    getVariablePath(path) {
      const pathArray = path.split('.')
      let value = this
      for (const prop of pathArray) {
        if (value && typeof value === 'object' && prop in value) {
          value = value[prop]
        } else {
          return undefined
        }
      }

      return value
    },

    mapComponentActions(field) {
      const actions = {}

      if (field.actions) {
        for (const action of field.actions) {
          actions[action.name] = (event) => {
            this[action.value](event)
          }
        }
      }

      return actions
    },

    cellValue(data) {
      if (!data.field.calculatedField) {
        return data.value ? data.value : '-'
      } else {
        if (data.field.calculatedFunction) {
          const functionName = data.field.calculatedFunction.trim()
          if (typeof this[functionName] === 'function') {
            return this[functionName](data.item)
          }
        }
      }
    },

    formatDate(date) {
      return typeof date === 'string' ? moment(date).toDate() : date
    },

    async onSortingChanged(ctx) {
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc

      this.loading = true
      await this.updateList()
      this.loading = false
    },

    onChangePage(page) {
      this.currentPage = page
      this.updateList()
    },

    onChangeFilter(str) {
      this.searchStr = str
      this.updateList()
    },

    onRowSelected(items) {
      if (items.length > 0) {
        this.$emit('value-selected', items[0])
      }
    },

    handleOk() {
      this.$emit('value-selected', undefined)
    },

    handleCancel() {
      this.$emit('value-selected', undefined)
    },

    handleClose() {
      this.$emit('value-selected', undefined)
    },
  },
}
</script>

<style lang="scss" scoped>
.folders-conteiner {
  height: 40vh;
  overflow-y: auto;
}

.advanced-filter .b-form-tag {
  font-size: 85%;
}

.folders-header {
  border-top: 1px solid #eef2f7;
  border-bottom: 2px solid #eef2f7;
  background-color: #fafbfe;

  .folders-title {
    margin: 8px 0;
    font-weight: bold;
  }
}

.folder-item {
  padding: 0.3rem;
  border: dotted #d2d7dd 1px;
  background-color: #fefefe;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 3px;

  &:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(244 245 246 / var(--tw-bg-opacity));
  }
}

.item-active {
  background-color: #e7ebf0;
  color: rgb(214, 120, 120);

  &:hover {
    background-color: #e7ebf0;
  }
}
</style>
