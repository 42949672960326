<template>
  <div :class="{ 'child-list': childList }">
    <div v-for="(field, idx) in fields" :key="idx">
      <div v-if="field.fieldType === 'object' && field.childs.length > 0">
        <div class="row mb-1 object-field" :class="{ 'item-active': currField === field }" @dblclick="onSelectField(field)" @click="onClickField(field)">
          <div class="col">
            <div>
              <i :class="{ 'ri-checkbox-indeterminate-line': field.expanded, 'ri-add-box-line': !field.expanded }" class="mr-2" v-b-toggle="field.name"></i>
              <span>{{ field.label }}</span>
            </div>
          </div>
        </div>
        <b-collapse :id="field.name" v-model="field.expanded">
          <ObjectField
            :child-list="true"
            :currField="currField"
            :fields="field.childs"
            @active-field="onClickField"
            @select-field="onSelectField"
          ></ObjectField>
        </b-collapse>
      </div>
      <div v-else>
        <div class="row mb-1 object-field" :class="{ 'item-active': currField === field }" @dblclick="onSelectField(field)" @click="onClickField(field)">
          <div class="col">
            <div>
              <i class="ri-checkbox-indeterminate-line mr-2"></i>
              <span>{{ field.label }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ObjectField',

  props: {
    fields: {
      type: [],
      required: true,
    },
    childList: {
      type: Boolean,
      default: false,
    },
    currField: {
      type: Object,
    },
  },

  methods: {
    onClickField(field) {
      this.$emit('active-field', field)
    },

    onSelectField(field) {
      this.$emit('select-field', field)
    },
  },
}
</script>

<style scoped>
.child-list {
  margin-left: 1rem;
}

.object-field {
  padding: 0.35rem;
  border: solid #adb5bd 1px;
  background-color: #fefefe;
  border-radius: 0.25rem;
  font-size: 16px;
  cursor: pointer;
}

.item-active {
  background-color: #dee2e6;
}
</style>