<template>
  <b-modal v-model="visible" :title="title" hide-footer no-close-on-backdrop>
    <div class="d-flex justify-content-center">
      <div class="spinner-border" role="status"></div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'LoadingDlg',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    visible: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },

  data() {
    return {
      title: `${this.$t('common.loading')}...`,
    }
  },
}
</script>

<style></style>
