<template>
  <div>
    <b-dropdown-item-button :disabled="item.disabled" @click="item.click">
      <i v-if="item.icon" :class="item.icon"></i>
      {{ item.title }}
    </b-dropdown-item-button>
    <div v-for="(listItem, i) in item.items" :key="i" class="nested-button">
      <b-dropdown-item-button :disabled="listItem.disabled || item.disabled" @click="listItem.click">
        <i v-if="listItem.icon" :class="listItem.icon"></i>
        {{ listItem.title }}
      </b-dropdown-item-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContextItem',
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style scoped>
.nested-button {
  margin-left: 10%;
}
</style>
