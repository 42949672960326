import axios from 'axios'
import _ from 'lodash'
import store from '@/store'

const apiURL = 'calendar_events'

const initState = {
  events: [],
  objectViews: [],
  currentEvents: [],
}

export const state = Object.assign({}, _.cloneDeep(initState))

export const getters = {
  events: (state: any) => state.events,
  currentEvents: (state: any) => state.currentEvents,
  presentation: (state: any) => (viewId: any) => {
    const objectView = state.objectViews.find((el: any) => el.viewId === viewId)

    let presentation = ''
    if (objectView) {
      presentation = objectView.object.name
    }

    return presentation
  },
}

export const mutations = {
  createEvent(state: any, event: any) {
    if (event.calendar) {
      event.backgroundColor = event.calendar.color
    }
    state.events.push(event)
  },

  updateEvent(state: any, event: any) {
    const index = state.events.findIndex((e: any) => e.id === event.id)
    if (event.calendar) {
      event.backgroundColor = event.calendar.color
    }
    return state.events.splice(index, 1, {
      ...state.events[index],
      ...event,
    })
  },

  async deleteEvent(state: any, event: any) {
    const index = state.events.findIndex((e: any) => e.id === event.id)
    return state.events.splice(index, 1)
  },

  setEvents(state: any, events: any) {
    state.events = events.map((event: any) => {
      if (event.calendar) {
        event.backgroundColor = event.calendar.color
      }
      return event
    })
  },

  updateCurrentEvents(state: any, currentEvents: any) {
    state.currentEvents = currentEvents
  },

  resetState(state: any) {
    Object.assign(state, _.cloneDeep(initState))
  },
}

export const actions = {
  async findAllEvents({ commit }: any, payload: any) {
    const res = await axios.get(`/${apiURL}`, payload)
    if (res.status === 200) {
      for (const event of res.data) {
        await axios.get(`/calendars/${event.calendarId}`).then((response) => {
          if (response.status === 200) {
            event.calendar.color = response.data.color
          }
        })
      }

      if (!payload?.noCommit) {
        commit('setEvents', res.data)
      }
      return res
    } else return false
  },

  async createEvent({ commit }: any, data: any) {
    const saveData = _.cloneDeep(data)
    const resItem = await axios.post(`/${apiURL}`, saveData)
    if (resItem.status === 200) {
      const event = _.cloneDeep(resItem.data)
      await axios.get(`/calendars/${event.calendarId}`).then((response) => {
        if (response.status === 200) {
          event.calendar = response.data
        }
      })
      commit('createEvent', event)
      return true
    } else return false
  },

  async updateEvent({ commit }: any, data: any) {
    const saveData = _.cloneDeep(data)
    const resItem = await axios.put(`/${apiURL}/${saveData.id}`, saveData)
    if (resItem.status === 200) {
      const event = _.cloneDeep(resItem.data)
      await axios.get(`/calendars/${event.calendarId}`).then((response) => {
        if (response.status === 200) {
          event.calendar = response.data
        }
      })
      commit('updateEvent', event)
      return true
    } else return false
  },

  async deleteEvent({ commit }: any, data: any) {
    try {
      const res = await axios.delete(`/${apiURL}/${data.id}`, { data })
      if (res.status === 200) {
        commit('deleteEvent', data)
      } else return false
    } catch (error) {
      console.error(error)
      return false
    }
  },

  async updateCurrentEvents({ commit, dispatch, state }: any, payload: any) {
    const calendar = payload?.calendar || null
    const calendarGroup = payload?.calendarGroup || null
    const events = state.events

    if (calendar !== 'all') {
      let currentEvents: Array<any> = []
      if (calendar[calendarGroup]) {
        if (calendar[calendarGroup].length > 0) {
          const result = events.filter((e: any) => calendar[calendarGroup].includes(e.calendarId))
          currentEvents = currentEvents.concat(result)
        }
      }
      commit('updateCurrentEvents', currentEvents)
    } else {
      commit('updateCurrentEvents', [...events])
    }
  },
}
