import moment from 'moment'
import axios from 'axios'
import _ from 'lodash'
import { ActionContext } from 'vuex'
import { IState } from '@/store/service/state'
import { commonState } from '../service/state'
import { commonMutations } from '../service/mutations'
import { commonGetters } from '../service/getters'
import { findAll, findByPk, createItem, updateItem, deleteItem, addNewItem, changeDeletionMark, resetState } from '../service/actions'

import BasicObject from '@/dto/IncomingEmail.json'

const apiUrl = 'incoming_emails'

export const state = Object.assign({}, _.cloneDeep(commonState))

export const mutations = { ...commonMutations }

export const actions = {
  async findAll(context: ActionContext<IState, any>, payload: any) {
    return findAll(context, apiUrl, payload)
  },

  async findByPk(context: ActionContext<IState, any>, payload: any) {
    return findByPk(context, apiUrl, payload, itemHundler)
  },

  async create(context: ActionContext<IState, any>, payload: any) {
    return createItem(context, apiUrl, payload)
  },

  async update(context: ActionContext<IState, any>, payload: any) {
    return updateItem(context, apiUrl, payload)
  },

  async smartUpdate(context: ActionContext<IState, any>, payload: any) {
    if (Array.isArray(payload.id)) {
      let result = true
      payload.id.forEach(async (id: any) => {
        return axios
          .put(`/incoming_emails/${id}`, payload.updateData)
          .then((response) => {
            if (response.status !== 200) {
              result = false
            }
          })
          .catch((error) => {
            console.error(error)
            result = false
          })
      })

      return result
    } else {
      return axios
        .put(`/incoming_emails/${payload.id}`, payload.updateData)
        .then((response) => {
          if (response.status === 200) {
            return true
          }
          return false
        })
        .catch((error) => {
          console.error(error)
          return false
        })
    }
  },

  async delete(context: ActionContext<IState, any>, payload: any) {
    return deleteItem(context, apiUrl, payload)
  },

  async changeDeletionMark(context: ActionContext<IState, any>, payload: any) {
    return changeDeletionMark(context, apiUrl, payload)
  },

  async addNew(context: ActionContext<IState, any>, payload: any) {
    return addNewItem(context, { basicObject: BasicObject }, payload)
  },

  async getLinkedDocs(context: ActionContext<IState, any>, payload: any) {
    try {
      const response = await axios.get(`/${apiUrl}/linked_doc`, payload)
      return response
    } catch (error) {
      throw new Error(error)
    }
  },

  async getCount(context: ActionContext<IState, any>, payload: any) {
    return axios
      .get(`/${apiUrl}/count`, payload)
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  },

  async getAttachment(context: ActionContext<IState, any>, payload: any) {
    return axios
      .post(`/${apiUrl}/attachment`, payload)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.error(error)
        return error
      })
  },

  async deleteLinkedDoc(context: ActionContext<IState, any>, payload: any) {
    try {
      const response = await axios.delete(`/${apiUrl}/linked_doc/${payload.params.id}`, payload)
      return response
    } catch (error) {
      throw new Error(error)
    }
  },

  async getFile(context: ActionContext<IState, any>, payload: any) {
    return axios
      .get(`/${apiUrl}/file/${payload.id}`, { responseType: 'blob', params: {} })
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.error(error)
        throw error
      })
  },

  async getFiles(context: ActionContext<IState, any>, payload: any) {
    return axios
      .get(`/${apiUrl}/files`, payload)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.error(error)
        throw error
      })
  },

  async fillConterparties(context: any, payload: any) {
    return axios
      .post(`/${apiUrl}/fill_counterparties`, payload)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.error(error)
        throw error
      })
  },

  async fillConterparty(context: any, payload: any) {
    return axios
      .post(`/${apiUrl}/fill_counterparty`, payload)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.error(error)
        throw error
      })
  },

  async setLabel(context: any, payload: any) {
    return axios
      .post(`/${apiUrl}/label`, payload)
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  },

  resetState(context: ActionContext<IState, any>) {
    resetState(context)
  },
}

function itemHundler(itemData: any) {
  itemData.object.createdAt = moment(itemData.object.createdAt).format('DD.MM.YYYY HH:mm:ss')
}

export const getters = { ...commonGetters }
